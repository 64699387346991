import useAxios from '@/utils/useAxios'

export const useSharedUserList = async (pageNumber: number) => {
  const { data, error, status, fetchData } = useAxios<any>()

  // fetch the shared user list
  await fetchData({
    url: '/multi-user/invite/',
    params: {
      page: pageNumber
    }
  })

  return { data, status, error }
}
