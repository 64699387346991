<script lang="ts" setup>
import router from '@/router'

import Row from 'primevue/row'
import Column from 'primevue/column'
import DataTable from 'primevue/datatable'
import ColumnGroup from 'primevue/columngroup'

import { formatDate } from '@/utils/helpers'
import { PermissionsCode } from '@/constants/permissions'
import { useHasPermission } from '@/utils/useHasPermission'
import { onMounted, reactive, watch } from 'vue'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { useConfirm } from 'primevue/useconfirm'
import { useRemoveBORequest } from '@/services/bulkOperation'
import { useBORequests } from '@/services/bulkOperation/useBORequests'
import { useToast } from 'primevue/usetoast'

const { hasPermissionCode } = useHasPermission()
const confirm = useConfirm()
const toast = useToast()

const dashboard = reactive({
  operations: [],
  loading: true,
  pageRows: 10,
  pageNumber: 1,
  totalRecords: 0
})

const fetchBORequests = (pageNumber: number = 1) => {
  useBORequests(pageNumber).then(({ data, error, status }) => {
    if (status.value == 200) {
      dashboard.operations = data.value?.results
      dashboard.totalRecords = data.value?.count
      dashboard.loading = false
    }
  })
}

const onPageChange = (event: any) => {
  dashboard.pageNumber = event.page + 1
}

const onConfirmDelete = (data) => {
  confirm.require({
    message: `Are you sure you want to remove the Bulk Operation '${data.name}'?`,
    header: 'Confirmation',
    acceptLabel: 'Confirm',
    rejectLabel: 'Cancel',
    rejectClass: 'bg-red-500 border-red-600 hover:bg-red-600 hover:border-red-600',
    accept: () => {
      useRemoveBORequest(data.operation_id).then(({ status }) => {
        if (status.value == 204) {
          dashboard.operations = dashboard.operations.filter(
            (val: any) => val.operation_id !== data.operation_id
          )
          dashboard.totalRecords -= 1
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Bulk Operation record deleted successfully.',
            life: 7000
          })
        }
      })
    },
    reject: () => {}
  })
}

// Watch changes
watch(
  () => dashboard.pageNumber,
  () => {
    dashboard.loading = true
    fetchBORequests(dashboard.pageNumber)
    dashboard.loading = false
  }
)

onMounted(() => {
  if (hasPermissionCode([PermissionsCode.CanViewBulkOperation])) fetchBORequests()
})
</script>

<template>
  <main class="mx-2 lg:mx-10">
    <h1 class="text-font-main text-xl font-semibold mt-5">Bulk Operation</h1>
    <div class="flex justify-end">
      <Button
        v-if="hasPermissionCode([PermissionsCode.CanCreateBulkOperation])"
        label="Generate Bulk Verification"
        severity="success"
        size="small"
        type="button"
        @click.prevent="router.push({ name: 'bulk-generate' })"
      />
    </div>
    <!--    Data Table-->
    <div class="shadow-md my-4">
      <DataTable
        v-if="hasPermissionCode([PermissionsCode.CanViewBulkOperation])"
        :loading="dashboard.loading"
        :paginator="dashboard.totalRecords > 0"
        :rows="dashboard.pageRows"
        :total-records="dashboard.totalRecords"
        :value="dashboard.operations"
        data-key="id"
        lazy
        size="small"
        striped-rows
        @page="onPageChange($event)"
      >
        <!--        Empty data slot-->
        <template #empty>
          <div class="text-center py-28">
            <p v-if="!dashboard.loading" class="text-center text-font-main">
              Get started by setting up a custom domain for your verifications <br />
              <router-link class="font-medium text-secondary-600" to="/settings">
                Setup Now
              </router-link>
            </p>
          </div>
        </template>
        <!--        Loading slot-->
        <template #loading>
          <p class="text-font-light">Loading... Please wait.</p>
        </template>
        <ColumnGroup type="header">
          <Row>
            <Column header="Batch ID" style="width: 20%" />
            <Column header="Batch Name" />
            <Column header="Status" />
            <Column header="Created By" style="width: 12%" />
            <Column header="Created On" style="width: 15%" />
            <Column :colspan="2" header="Actions" style="width: 15%" />
          </Row>
        </ColumnGroup>
        <Column field="operation_id" />
        <Column class="max-w-80" field="name" />
        <Column field="status" />
        <Column field="created_by" header="Created By" />
        <Column field="created" header="Created On">
          <!--suppress JSUnresolvedReference -->
          <template #body="{ data }">
            {{ formatDate(data?.created) }}
          </template>
        </Column>
        <Column :exportable="false" header="Actions">
          <template #body="{ data }">
            <button
              v-if="hasPermissionCode([PermissionsCode.CanRemoveBulkOperation])"
              class="action-button"
              @click="onConfirmDelete(data)"
            >
              <TrashIcon class="w-5 stroke-1.5" />
            </button>
          </template>
        </Column>
      </DataTable>
    </div>
  </main>
</template>

<style scoped></style>
