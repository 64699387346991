import useAxios from '@/utils/useAxios'

export const useAddCustomDomain = async (domain: string) => {
  // Add Custom Domain Endpoint
  const { data, error, status, fetchData } = useAxios<any>()

  // Create Custom Domain
  await fetchData({
    url: '/custom-domain/',
    method: 'POST',
    data: {
      domain: domain
    }
  })
  return { data, error, status }
}
