<script lang="ts" setup>
import * as Yup from 'yup'
import * as yup from 'yup'
import AuthenticationCard from '@/components/Card/AuthenticationCard.vue'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { useForm } from 'vee-validate'
import Password from 'primevue/password'
import Divider from 'primevue/divider'
import { useForgetPassword } from '@/services/authentication/useForgetPassword'
import { useToast } from 'primevue/usetoast'

// Emit definition
const emit = defineEmits<{
  (event: 'NavigateBack'): void
  (event: 'PasswordResetCompleted'): void
}>()

// Props definition
const props = defineProps<{ email: string }>()
const toast = useToast()

const { defineField, handleSubmit, errors, meta, setErrors } = useForm({
  validationSchema: yup.object({
    password: yup.string().required().min(8).label('Password'),
    passwordConfirmation: Yup.string()
      .min(8)
      .required()
      .label('Confirm Password')
      .oneOf([Yup.ref('password')], 'Passwords must match')
  })
})

const [password, passwordAttr] = defineField('password')
const [passwordConfirmation, passwordConfirmationAttrs] = defineField('passwordConfirmation')

const onPasswordResetSubmit = handleSubmit((values) => {
  useForgetPassword(props.email, values.password).then(({ data, status, error }) => {
    if (status.value == 200) {
      toast.add({
        summary: 'Success Message',
        severity: 'success',
        detail: data.value?.message,
        life: 7000
      })
      emit('PasswordResetCompleted')
    }
    if (status.value == 400) setErrors({ password: error.value?.details?.password })
    if (status.value == 429) {
      toast.add({
        severity: 'warn',
        summary: 'Warning Message',
        detail: 'Attempt limit exceeded. Try again after a minute',
        life: 7000
      })
    }
  })
})
</script>

<template>
  <AuthenticationCard>
    <template #header>
      <span class="flex pl-5 py-5 cursor-pointer" @click="emit('NavigateBack')">
        <ChevronLeftIcon class="w-6 text-font-main" />
        <span class="text-font-main text-md">Back</span>
      </span>
    </template>
    <template #title>Create a new password</template>
    <template #content>
      <span class="text-lg text-font-main">
        In order to protect your account, make sure your password:
      </span>
      <form class="mt-5" @submit.prevent="onPasswordResetSubmit">
        <div>
          <Password
            id="password"
            v-model="password"
            class="w-full"
            placeholder="Password"
            type="password"
            v-bind="passwordAttr"
            aria-label="Password"
            aria-labelledby="password"
          >
            <template #header>
              <span class="text-font-main text-sm">Pick a password</span>
            </template>
            <template #footer>
              <Divider />
              <p class="text-font-main">Must be:</p>
              <ul class="pl-4 ml-2 mt-0 text-font-main text-sm">
                <li>At least one lowercase</li>
                <li>At least one uppercase</li>
                <li>At least one numeric</li>
                <li>Minimum 8 characters</li>
              </ul>
            </template>
          </Password>
          <InlineMessage v-show="errors.password" severity="error">
            {{ errors.password }}
          </InlineMessage>
        </div>
        <div class="mt-5 mb-10">
          <Password
            id="passwordConfirmation"
            v-model="passwordConfirmation"
            :feedback="false"
            class="w-full"
            placeholder="Confirm Password"
            toggle-mask
            type="password"
            v-bind="passwordConfirmationAttrs"
            aria-label="Password"
            aria-labelledby="password"
          />
          <InlineMessage v-show="errors.passwordConfirmation" severity="error">
            {{ errors.passwordConfirmation }}
          </InlineMessage>
        </div>
        <Button
          :disabled="!meta.valid"
          class="w-full"
          label="Set Password"
          outlined
          severity="success"
          type="submit"
        />
      </form>
    </template>
  </AuthenticationCard>
</template>

<style scoped></style>
