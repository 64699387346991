import useAxios from '@/utils/useAxios'

export const useInviteSharedUser = async (sharedUserEmail: string, accessLevelId: number) => {
  const { data, error, status, fetchData } = useAxios<any>()

  // Invite share user
  await fetchData({
    url: '/multi-user/invite/',
    method: 'POST',
    data: {
      shared_user_email: sharedUserEmail,
      access_level_id: accessLevelId
    }
  })

  return { data, error, status }
}
