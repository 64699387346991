import { defineStore } from 'pinia'

interface BulkOperationStoreInterface {
  activeStep: number
  mediaIdList: number[]
  mediaNameList: string[]
  previewMediaURL: string | null
}

export const useBulkOperationStore = defineStore('bulk-operation', {
  state: (): BulkOperationStoreInterface => ({
    activeStep: 0,
    mediaIdList: [],
    mediaNameList: [],
    previewMediaURL: null
  }),
  getters: {
    getActiveStep: (state: BulkOperationStoreInterface): number => {
      return state.activeStep
    },
    getMediaIdList: (state: BulkOperationStoreInterface): number[] => {
      return state.mediaIdList
    },
    getPreviewMediaURL: (state: BulkOperationStoreInterface): string | null => {
      return state.previewMediaURL
    }
  },
  actions: {
    setActiveStep(step: number): void {
      this.activeStep = step
    },

    setPreviewMediaURL(mediaURL: string): void {
      this.previewMediaURL = mediaURL
    },

    setMediaIDList(id: number): void {
      this.mediaIdList.push(id)
    },

    setMediaFileName(mediaFileName: string): void {
      this.mediaNameList.push(mediaFileName)
    },

    $reset(): void {
      this.activeStep = 0
      this.mediaIdList = []
      this.mediaNameList = []
      this.previewMediaURL = null
      this.$dispose()
    }
  }
})
