import { useAuthStore } from '@/stores/authentication'

export interface PermissionInterface {
  code: string
  category: string
  permission: string
  description: string
}

export const useHasPermission = (): {
  hasPermissionCode: (permissionCode: string[]) => boolean
} => {
  const authStore = useAuthStore()

  const hasPermissionCode = (permissionCode: string[]): boolean => {
    // Return true for admin user.
    if (!authStore.getOwnerUserDetails) return true

    // Fetch all permissions.
    const grantedPermissionsCodeSet = new Set(
      // @ts-ignore: Property permission does not exist on type object
      authStore.getSharedUserAccess.permissions.map((obj: PermissionInterface) => obj.code)
    )

    return permissionCode.every((code) => grantedPermissionsCodeSet.has(code))
  }

  return { hasPermissionCode }
}
