<script lang="ts" setup>
import Accordion, { type AccordionPassThroughOptions } from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'

const accordionPT: AccordionPassThroughOptions = {
  accordiontab: {
    root: {
      class: 'mb-2'
    },
    header: ({ props: e }: any) => ({
      class: [
        {
          'select-none pointer-events-none cursor-default opacity-60':
            e == null ? void 0 : e.disabled
        }
      ]
    }),
    headerAction: ({ context: e }: any) => ({
      class: [
        'font-bold',
        'leading-none',
        'flex items-center',
        'relative',
        'p-2',
        'rounded-t-md',
        { 'rounded-br-md rounded-bl-md': !e.active, 'rounded-br-0 rounded-bl-0': e.active },
        'border border-surface-200 dark:border-surface-700',
        'bg-surface-50 dark:bg-surface-800',
        'text-surface-600 dark:text-surface-0/80',
        { 'text-surface-900': e.active },
        'transition duration-200 ease-in-out',
        'transition-shadow duration-200',
        'hover:bg-surface-100 dark:hover:bg-surface-700/40',
        'hover:text-surface-900',
        'focus:outline-none focus:outline-offset-0 focus-visible:ring focus-visible:ring-primary-400/50 ring-inset dark:focus-visible:ring-primary-300/50',
        'cursor-pointer no-underline select-none'
      ]
    }),
    headerIcon: {
      class: 'inline-block mr-2'
    },
    headerTitle: {
      class: 'leading-none text-font-main text-sm lg:text-base'
    },
    content: {
      class: [
        'p-5',
        'rounded-tl-none rounded-tr-none rounded-br-lg rounded-bl-lg',
        'border-t-0',
        'bg-surface-0 dark:bg-surface-800',
        'border border-surface-200 dark:border-surface-700',
        'text-surface-700 dark:text-surface-0/80'
      ]
    },
    transition: {
      enterFromClass: 'max-h-0',
      enterActiveClass:
        'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
      enterToClass: 'max-h-[1000px]',
      leaveFromClass: 'max-h-[1000px]',
      leaveActiveClass:
        'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
      leaveToClass: 'max-h-0'
    }
  }
}
</script>

<template>
  <p class="text-font-main text-medium lg:text-lg font-medium">
    Maybe we’e already answered your questions. Here’s our FAQs
  </p>
  <Accordion :pt="accordionPT" class="pt-5" lazy multiple>
    <AccordionTab header="What is the cost of QR Mark?">
      <p>
        During the beta version, QR Mark is entirely free to use. However, charges will be
        implemented once we transition out of the beta phase.
      </p>
    </AccordionTab>
    <AccordionTab header="Does using QR Mark require software development knowledge?">
      <p>
        After initial setup of the custom domain (requires configuration in DNS settings), no
        software development knowledge is required to use QR Mark. You can either opt for Google
        Docs/Microsoft 365 add-ons or directly upload the PDF version in the application dashboard
        to add verification images.
      </p>
    </AccordionTab>
    <AccordionTab
      header="Can someone fake the QR Code added to the document using a different QR Code tool?"
    >
      <p>
        Counterfeiters can generate their QR Code and URL, leading users to a counterfeit
        page/content. However, counterfeiters cannot use your company/brand domain in their URLs.
        This is why QR Mark makes it mandatory to setup a custom domain for the verification URLs so
        that end-users (people who scan to verify) can trust the page/content as it is linked to the
        company's/brand's domain.
      </p>
    </AccordionTab>
    <AccordionTab header="Does QR Mark store my important data?">
      <template #default>
        <p class="mb-2">QR Mark does store some of your data, including:</p>
        <ul class="list-decimal list-inside mb-2">
          <li>Document Name</li>
          <li>Document Link (public)</li>
          <li>Email address of document creator</li>
          <li>Document creation time If the verification system</li>
        </ul>
        <p>
          was created using the Upload feature, then the system stores a copy of the document. But
          don’t worry, QR Mark employs the highest standards of data protection and security to
          safeguard user information and privacy. Only authorized people who have access to the
          original document (thereby having access to the verification image) will be able to view
          these details and a digital copy of the document.
        </p>
      </template>
    </AccordionTab>
    <AccordionTab
      header="What if the QR Code used in my document is damaged, or if the scanning user is unable to view my document due to a technical error?"
    >
      <p>
        We provide a QR Code as well as a verification URL. If the QR Code is damaged or no longer
        works, the end-user can easily click the verification URL to verify. If the landing page
        doesn't load due to a technical issue, user can reach out to the customer relations team for
        support.
      </p>
    </AccordionTab>
    <AccordionTab header="What is the cost of QR Mark?">
      <template #default>
        <p>Two things can happen, depending on how the verification system was created.</p>
        <ul class="list-decimal list-inside my-2">
          <li>
            Using Upload: In this case, the verification will continue to work as the document is
            uploaded to QR Mark's database
          </li>
          <li>
            Via Add-on: In this case, the landing page will open (with basic details) but the user
            will not be able to view the original document as the link will no longer work
          </li>
        </ul>
      </template>
    </AccordionTab>
  </Accordion>
</template>

<style scoped></style>
