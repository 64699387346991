import useAxios from '@/utils/useAxios'

export const useRemoveCustomDomain = async (customDomainId: number | null) => {
  // Access token endpoint.
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: `/custom-domain/${customDomainId}/`,
    method: 'DELETE'
  }).then(() => {
    console.log('Successfully clear the incomplete domain setup')
  })

  return { status }
}
