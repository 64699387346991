<!--Script-->
<script lang="ts" setup xmlns="http://www.w3.org/1999/html">
import { onBeforeUnmount, onMounted, ref } from 'vue'
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import Divider from 'primevue/divider'
import Password from 'primevue/password'
import { useRegistration } from '@/services/authentication/useRegistration'
import { LockClosedIcon } from '@heroicons/vue/24/outline'
import AuthenticationCard from '@/components/Card/AuthenticationCard.vue'
import ContinueWithGoogle from '@/components/Button/ContinueWithGoogle.vue'
import EmailVerificationCard from '@/components/Card/EmailVerificationCard.vue'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { useTitle } from '@vueuse/core'
import { useHeadTag } from '@/utils/useHeadTag'
import ContinueWithSSO from '@/components/Button/ContinueWithSSO.vue'

// The code `const { defineField, handleSubmit, errors, meta } = useForm({ validationSchema: {...} })`
// is using the `useForm` function from the `vee-validate` library to handle form validation.
const { defineField, handleSubmit, errors, meta, setErrors } = useForm({
  validationSchema: {
    name: yup.string().required().min(3).label('Name'),
    email: yup.string().required().email().label('Email'),
    password: yup.string().required().min(8).label('Password')
  }
})

// The code `const [name] = defineField('name')`, `const [password] = defineField('password')`, and
// `const [email] = defineField('email')` are using array destructuring to assign the returned values
// from the `defineField` function to the variables `name`, `password`, and `email` respectively.
const [name, nameAttr] = defineField('name')
const [password, passwordAttr] = defineField('password')
const [email, emailAttr] = defineField('email')

// Email Verification
const showEmailVerificationCard = ref<boolean>(false)
const isLoading = ref<boolean>(false)

// Toast message
const toast = useToast()

// router
const router = useRouter()

// Title
const title = useTitle()
title.value = 'QR Mark Sign Up for Easy Document Security'

// The `const onSubmit` is a function that is triggered when the form is submitted. It uses the
// `handleSubmit` function from the `vee-validate` library to handle form validation.
const onSignupSubmit = handleSubmit((values) => {
  isLoading.value = true
  useRegistration(values.name, values.email, values.password).then(({ status, error }) => {
    if (status.value == 201) {
      showEmailVerificationCard.value = true
      toast.add({
        summary: 'Success Message',
        severity: 'success',
        detail: 'Account created successfully. \n Please sign in using your credentials.',
        life: 7000
      })
      isLoading.value = false
    }
    if (status.value == 400) {
      isLoading.value = false
      setErrors({ name: error.value?.details?.first_name?.[0] })
      setErrors({ email: error.value?.details?.email?.[0] })
      setErrors({ password: error.value?.details?.password?.[0] })
    }
    if (status.value == 429) {
      isLoading.value = false
      toast.add({
        severity: 'warn',
        summary: 'Warning Message',
        detail: 'Attempt limit exceeded. Try again after a minute',
        life: 7000
      })
    }
  })
})

const afterEmailVerified = () => {
  if (import.meta.env.VUE_ENVIRONMENT === 'production') {
    // GTM Signup conversion tag trigger.
    // @ts-ignore: Property 'dataLayer' does not exist on type 'Window & typeof globalThis'.
    window.dataLayer.push({
      event: 'signupCompletedEvent',
      email: email
    })
  }
  router.push({ name: 'signin' })
}

const { addTag, updateTag, removeTag } = useHeadTag()
onMounted(() => {
  addTag('meta', 'property', 'og:locale', 'content', 'en_us')
  addTag('meta', 'property', 'og:type', 'content', 'website')
  addTag('meta', 'property', 'og:site_name', 'content', 'QR Mark')
  addTag('meta', 'property', 'og:url', 'content', 'https://app.qrmark.com/signup')
  addTag('meta', 'property', 'og:title', 'content', 'QR Mark Login for Easy Documents Security')
  addTag(
    'meta',
    'property',
    'og:description',
    'content',
    'Sign up to create your QR Mark account start securing your documents from counterfeiting now.'
  )
  addTag(
    'meta',
    'property',
    'og:image',
    'content',
    'https://qrmark-production.s3.us-west-1.amazonaws.com/media/logo/qrmark-full-text-logo.png'
  )
  addTag('link', 'rel', 'canonical', 'href', 'https://app.qrmark.com/signup')

  addTag('meta', 'name', 'keywords', 'content', 'QR Mark Sign up')
})

onBeforeUnmount(() => {
  removeTag('link', 'rel', 'canonical')
  removeTag('meta', 'name', 'keywords')
})
</script>

<!--Template-->
<template>
  <div class="flex flex-col justify-center items-center h-screen space-y-6">
    <!-- QR Mark Logo-->
    <a href="https://qrmark.com">
      <picture>
        <source srcset="@/assets/img/qrmark-logo-full-text.webp" type="image/webp" />
        <img alt="QR Mark Logo" src="@/assets/img/qrmark-logo-full-text.svg" width="200" />
      </picture>
    </a>

    <!-- Signup Card -->
    <AuthenticationCard v-show="!showEmailVerificationCard">
      <template #title><h1>Create an account</h1></template>
      <template #content>
        <div class="space-y-4">
          <ContinueWithGoogle />
          <ContinueWithSSO />
        </div>
        <Divider layout="horizontal" align="center" type="solid">or</Divider>
        <form class="space-y-5" @submit="onSignupSubmit">
          <div>
            <div class="flex items-center">
              <InputText
                id="name"
                type="text"
                size="small"
                placeholder="Full Name"
                v-model="name"
                v-bind="nameAttr"
              />
              <LockClosedIcon class="w-5 h-5 mx-2 text-font-main" />
            </div>
            <InlineMessage v-show="errors.name" severity="error">{{ errors.name }}</InlineMessage>
          </div>
          <div>
            <div class="flex items-center">
              <InputText
                id="email"
                type="email"
                size="small"
                placeholder="Email"
                v-model="email"
                v-bind="emailAttr"
              />
              <LockClosedIcon class="w-5 h-5 mx-2 text-font-main" />
            </div>
            <InlineMessage v-show="errors.email" severity="error">{{ errors.email }}</InlineMessage>
          </div>
          <div>
            <div class="flex items-center">
              <Password
                id="password"
                type="password"
                placeholder="Password"
                class="w-full"
                v-model="password"
                v-bind="passwordAttr"
                toggle-mask
                aria-label="Password"
                aria-labelledby="password"
              >
                <template #header>
                  <span class="text-font-main text-sm">Pick a password</span>
                </template>
                <template #footer>
                  <Divider />
                  <p class="text-font-main">Must be:</p>
                  <ul class="pl-4 ml-2 mt-0 text-font-main text-sm">
                    <li>At least one lowercase</li>
                    <li>At least one uppercase</li>
                    <li>At least one numeric</li>
                    <li>Minimum 8 characters</li>
                  </ul>
                </template>
              </Password>
              <LockClosedIcon class="w-5 h-5 mx-2 text-font-main" />
            </div>
            <InlineMessage v-show="errors.password" severity="error">
              {{ errors.password }}
            </InlineMessage>
          </div>
          <!--Signup Button-->
          <Button
            type="submit"
            label="Create Account"
            severity="success"
            :loading="isLoading"
            class="bg-primary-main w-full"
            :disabled="!meta.valid"
            outlined
          />
        </form>
        <span class="text-font-light flex justify-center mt-5">
          Already have an account?
          <router-link :to="{ name: 'signin' }" class="px-2 text-secondary-light">
            Sign in
          </router-link>
        </span>
      </template>
    </AuthenticationCard>
    <EmailVerificationCard
      v-if="showEmailVerificationCard"
      :email="email"
      source="signup"
      @navigate-back="showEmailVerificationCard = false"
      @email-verification-completed="afterEmailVerified"
    />
    <!--    Terms and condition-->
    <span class="text-sm mt-5 px-5 text-font-light">
      By signing up you agree to our
      <a class="underline font-medium" href="https://qrmark.com/terms-of-service" target="_blank">
        Terms of Service</a
      >
      &
      <a class="underline font-medium" href="https://trycon.io/legal/privacy-notice" target="_blank"
        >Privacy Policy
      </a>
      <br />
      <span>
        QR Mark's use and transfer of information received from Google APIs will adhere to<br />
        <a
          class="underline font-medium"
          href="https://developers.google.com/terms/api-services-user-data-policy"
          target="_blank"
          >Google API Services User Data Policy</a
        >, including the Limited Use Requirement.
      </span>
    </span>
  </div>
</template>

<style scoped lang="postcss"></style>
