import useAxios from '@/utils/useAxios'

export interface VerificationListInterface {
  hash: string
  document_name: string
  reference_url: string
  created_name: string
  created: string
}

export const useVerificationList = async (pageNumber: number) => {
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: '/qr/list/',
    method: 'GET',
    params: {
      page: pageNumber
    }
  })
    .then(() => {
      console.log('Successfully fetch the data')
    })
    .catch(() => {
      console.log('Exception occur while fetching the data', error)
    })
    .finally(() => {})
  return { data, error, status }
}
