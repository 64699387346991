<script lang="ts" setup>
import * as yup from 'yup'
import { onMounted, reactive, watch } from 'vue'
import Column from 'primevue/column'
import Sidebar from 'primevue/sidebar'
import DataTable from 'primevue/datatable'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { CheckCircleIcon, TrashIcon, XCircleIcon } from '@heroicons/vue/24/outline'
import { useForm } from 'vee-validate'
import { useToast } from 'primevue/usetoast'
import {
  useDeleteSharedUser,
  useFetchAccessLevel,
  useInviteSharedUser,
  useSharedUserList,
  useUpdateSharedUser
} from '@/services/multi-user'
import { useAuthStore } from '@/stores/authentication'
import { useConfirm } from 'primevue/useconfirm'
import { useHasPermission } from '@/utils/useHasPermission'
import { PermissionsCode } from '@/constants/permissions'

const sharedUserState = reactive({
  visibleSidebar: false,
  isLoading: false,
  roles: [],
  accessLevels: [],
  allPermissions: [],
  permissionGranted: [],
  permissionDenied: [],
  sharedUsersList: [],
  editingRows: [],
  pageNumber: 1,
  totalRecords: 0,
  pageRows: 5
})

const authStore = useAuthStore()
const { hasPermissionCode } = useHasPermission()

// Shared User Sidebar pass through
const sharedUserSidebarPT = {
  root: ({ props: e }: any) => ({
    class: [
      'flex flex-col',
      'relative',
      {
        '!transition-none !transform-none !w-screen !h-screen !max-h-full !top-0 !left-0':
          e.position == 'full'
      },
      {
        'h-full w-10/12 md:w-1/3': e.position == 'left' || e.position == 'right',
        'h-auto w-full': e.position == 'top' || e.position == 'bottom'
      },
      'border-0 dark:border',
      'shadow-lg',
      'bg-surface-0 dark:bg-surface-800',
      'text-surface-700 dark:text-white/80',
      'dark:border-surface-700',
      'transition-transform',
      'duration-300',
      'pointer-events-auto'
    ]
  })
}

// Fieldset pass through
// noinspection JSUnusedGlobalSymbols
const fieldsetPT = {
  root: {
    class: [
      'block',
      'px-4 pt-2 py-3',
      'inline-size-min',
      'rounded-md',
      'border border-surface-200 dark:border-surface-700',
      'bg-surface-0 dark:bg-surface-900',
      'text-surface-700 dark:text-surface-0/80'
    ]
  },
  legend: ({ props }: any) => ({
    class: [
      // Font
      'font-bold',
      'leading-none',
      //Spacing
      { 'p-0': props.toggleable, 'p-3': !props.toggleable },
      // Shape
      'rounded-md',
      // Color
      'text-surface-700 dark:text-surface-0/80',
      'border border-surface-200 dark:border-surface-700',
      'bg-surface-50 dark:bg-surface-900',
      // Transition
      'transition-none',
      // States
      {
        'hover:bg-surface-100 hover:border-surface-200 hover:text-surface-900 dark:hover:text-surface-0/80 dark:hover:bg-surface-800/80':
          props.toggleable
      }
    ]
  }),
  toggler: ({ props }: any) => ({
    class: [
      // Alignments
      'flex items-center justify-center',
      'relative',
      //Spacing
      { 'p-5': props.toggleable },
      // Shape
      { 'rounded-md': props.toggleable },
      // Color
      { 'text-surface-700 dark:text-surface-200 hover:text-surface-900': props.toggleable },
      // States
      { 'hover:text-surface-900 dark:hover:text-surface-100': props.toggleable },
      {
        'focus-visible:outline-none focus-visible:outline-offset-0 focus-visible:ring focus-visible:ring-inset focus-visible:ring-primary-400/50 dark:focus-visible:ring-primary-300/50':
          props.toggleable
      },
      // Misc
      {
        'transition-none cursor-pointer overflow-hidden select-none': props.toggleable
      }
    ]
  }),
  togglerIcon: {
    class: 'mr-2 inline-block'
  },
  legendTitle: {
    class: 'flex items-center justify-center leading-none'
  },
  content: {
    class: 'p-3'
  },
  transition: {
    enterFromClass: 'max-h-0',
    enterActiveClass:
      'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
    enterToClass: 'max-h-[1000px]',
    leaveFromClass: 'max-h-[1000px]',
    leaveActiveClass:
      'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
    leaveToClass: 'max-h-0'
  }
}

const toast = useToast()
const confirm = useConfirm()

// Form validation
const { defineField, handleSubmit, meta } = useForm({
  validationSchema: {
    email: yup.string().email().required("User's email address is required.").label('email'),
    role: yup.string().required('Please select the role.').label('role').default('Viewer')
  },
  initialValues: {
    email: '',
    role: 'Viewer'
  }
})

// Field definition
const [email, emailAttr] = defineField('email')
const [role, roleAttr] = defineField('role')

// Fetch Access level
const fetAccessLevel = () => {
  useFetchAccessLevel().then(({ data, status, error }) => {
    if (status.value == 200) {
      // Set access level response.
      sharedUserState.accessLevels = data.value?.results

      // All available roles
      sharedUserState.roles = data.value?.results.map((level: any) => level.name)

      // Admin permission
      const admin: any = data.value?.results.find((level: any) => level.name === 'Admin')
      sharedUserState.allPermissions = admin.permissions.map(
        (permission: any) => permission.description
      )

      // Granted viewer permission
      const viewer: any = data.value?.results.find((level: any) => level.name === 'Viewer')
      sharedUserState.permissionGranted = viewer.permissions.map(
        (permission: any) => permission.description
      )
      // Denied viewer permission
      sharedUserState.permissionDenied = sharedUserState.allPermissions.filter(
        (value) => !sharedUserState.permissionGranted.includes(value)
      )
    }
    // Toast message in case of error.
    if (error.value) {
      toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Something went wrong. Please reach out to us at support@qrmark.com ',
        life: 7000
      })
    }
  })
}

// Generate permission details.
const generatePermissionsDetails = (event: any) => {
  const roleName = event ? event.value : 'Viewer'
  const level: any = sharedUserState.accessLevels.find((level: any) => level.name === roleName)
  // Permission granted
  sharedUserState.permissionGranted = level.permissions.map(
    (permission: any) => permission.description
  )
  // Permission denied
  sharedUserState.permissionDenied = sharedUserState.allPermissions.filter(
    (value) => !sharedUserState.permissionGranted.includes(value)
  )
}

// Invite shared user form submit
const onSubmitInviteUserForm = handleSubmit((values) => {
  sharedUserState.isLoading = true
  // find the id of the role
  const level: any = sharedUserState.accessLevels.find((level: any) => level.name === values.role)
  // invite share user endpoint.
  useInviteSharedUser(values.email, level.id).then(({ data, error, status }) => {
    if (status.value == 201) {
      sharedUserState.isLoading = false
      sharedUserState.visibleSidebar = false
      toast.add({
        severity: 'success',
        summary: 'Success Message',
        detail: `Invitation request sent to ${values.email}`,
        life: 8000
      })
      fetchSharedUserList()
    }
  })
})

// Fetch the Shared user list
const fetchSharedUserList = (pageNumber: number = 1) => {
  sharedUserState.isLoading = true
  useSharedUserList(pageNumber).then(({ data, status, error }) => {
    // append owner user information in the list.
    sharedUserState.sharedUsersList = data.value?.results
    sharedUserState.totalRecords = data.value?.count
    sharedUserState.isLoading = false
  })
}

// Row edit save
const onRowEditSave = (event: any) => {
  // find the id of the role
  const level: any = sharedUserState.accessLevels.find((level: any) => level.name === role.value)
  // Permission update
  useUpdateSharedUser(event.data.id, level.id).then(({ data, status, error }) => {
    if (status.value == 200) {
      toast.add({
        summary: 'Success Message',
        severity: 'success',
        detail: `Updated the Role of ${data.value.shared_user_email} to ${data.value.access_level.name}`,
        life: 7000
      })
      fetchSharedUserList()
    }
  })
}
// Remove shared user.
const onRemoveSharedUser = (event: any) => {
  confirm.require({
    message: `Are you sure you want to remove this user ${event.shared_user_email}? The account will be deleted permanently.`,
    header: 'Confirmation',
    acceptLabel: 'Confirm',
    rejectLabel: 'Cancel',
    rejectClass: 'bg-red-500 border-red-600 hover:bg-red-600 hover:border-red-600',
    accept: () => {
      useDeleteSharedUser(event.id).then(({ data, status, error }) => {
        if (status.value == 204) {
          sharedUserState.sharedUsersList = sharedUserState.sharedUsersList.filter(
            (val: any) => val.id !== event.id
          )
          sharedUserState.totalRecords -= 1
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: `${event.shared_user_email} account removed.`,
            life: 8000
          })
        }
      })
    },
    reject: () => {}
  })
}

const onPageChange = (event: any) => {
  sharedUserState.pageNumber = event.page + 1
}

// Watcher
watch(
  () => sharedUserState.pageNumber,
  () => {
    fetchSharedUserList(sharedUserState.pageNumber)
  }
)

// on mount functions
onMounted(() => {
  if (hasPermissionCode([PermissionsCode.CanAddSharedUser])) fetAccessLevel()
  if (hasPermissionCode([PermissionsCode.CanViewSharedUser])) fetchSharedUserList()
})
</script>

<template>
  <p class="text-font-main">
    Use this feature to invite other users to access and manage account by assigning a role.
  </p>
  <Button
    class="bg-primary-main px-8 mt-4"
    label="Invite User"
    outlined
    severity="success"
    size="small"
    @click="sharedUserState.visibleSidebar = true"
    v-if="hasPermissionCode([PermissionsCode.CanAddSharedUser])"
  />
  <!--  Shared user Invitation sidebar-->
  <Sidebar
    v-model:visible="sharedUserState.visibleSidebar"
    :pt="sharedUserSidebarPT"
    position="right"
  >
    <template #header>
      <div>
        <p class="text-font-main text-md font-bold subpixel-antialiased">Invite User</p>
        <p class="text-font-main text-sm subpixel-antialiased">
          Assign role, permissions and invite user to manage account
        </p>
      </div>
    </template>

    <template #default>
      <form class="space-y-5" @submit="onSubmitInviteUserForm">
        <p>
          <span class="base-text text-font-main">User's Email:</span>
          <InputText
            v-model="email"
            placeholder="Invite user email"
            size="small"
            type="email"
            v-bind="emailAttr"
          />
        </p>
        <div>
          <span class="base-text text-font-main">User's Role:</span>
          <Dropdown
            v-model="role"
            :options="sharedUserState.roles"
            class="w-full md:w-full"
            placeholder="Select a Role"
            v-bind="roleAttr"
            @change="generatePermissionsDetails"
          />
        </div>
        <div>
          <Fieldset :pt="fieldsetPT" legend="Permissions">
            <div class="space-y-10">
              <div>
                <p class="flex space-x-2">
                  <CheckCircleIcon class="w-6 stroke-green-600" />
                  <span class="font-semibold">Permitted Actions</span>
                </p>
                <hr class="my-2" />
                <ul class="list-inside p-4">
                  <li
                    v-for="pem in sharedUserState.permissionGranted"
                    class="list-disc text-font-light"
                  >
                    {{ pem }}
                  </li>
                </ul>
              </div>
              <div v-if="sharedUserState.permissionDenied.length > 0">
                <p class="flex space-x-2">
                  <XCircleIcon class="w-6 stroke-red-600" />
                  <span class="font-semibold">Restricted Actions</span>
                </p>
                <hr class="my-2" />
                <ul class="list-inside p-4">
                  <li
                    v-for="pem in sharedUserState.permissionDenied"
                    class="list-disc text-font-light"
                  >
                    {{ pem }}
                  </li>
                </ul>
              </div>
            </div>
          </Fieldset>
        </div>
        <Button
          :disabled="!meta.valid"
          class="w-full mt-5"
          label="Invite User"
          outlined
          severity="success"
          size="small"
          type="submit"
        />
      </form>
    </template>
  </Sidebar>
  <!--  END Shared user Invitation sidebar -->

  <!--  Account owner information-->
  <div v-if="authStore.ownerUserDetails" class="my-4">
    <span class="text-font-main pl-2 text-md font-semibold subpixel-antialiased">
      Account Owner:
    </span>
    <span class="px-2">
      {{ authStore.getOwnerUserDetails.first_name }} ({{ authStore.getOwnerUserDetails.email }})
    </span>
  </div>
  <!--  END Account owner information-->

  <!--  Shared User Data Table Component-->
  <p
    v-if="hasPermissionCode([PermissionsCode.CanViewSharedUser])"
    class="text-font-main pl-2 text-md font-extrabold subpixel-antialiased my-6"
  >
    Users
  </p>
  <DataTable
    v-model:editingRows="sharedUserState.editingRows"
    :paginator="sharedUserState.totalRecords > 0 ?? null"
    :rows="sharedUserState.pageRows"
    :total-records="sharedUserState.totalRecords"
    :value="sharedUserState.sharedUsersList"
    :loading="sharedUserState.isLoading"
    data-key="id"
    edit-mode="row"
    lazy
    size="small"
    striped-rows
    @row-edit-save="onRowEditSave"
    @page="onPageChange($event)"
    v-if="hasPermissionCode([PermissionsCode.CanViewSharedUser])"
  >
    <template #loading>
      <p class="text-center text-font-main pb-4">Loading... Please wait.</p>
    </template>
    <template #empty>
      <p v-if="!sharedUserState.isLoading" class="text-center text-font-main">No users found.</p>
    </template>
    <ColumnGroup type="header">
      <Row>
        <Column header="Email" />
        <Column header="Role" />
        <Column header="Status" />
        <Column
          v-if="
            hasPermissionCode([
              PermissionsCode.CanAddSharedUser,
              PermissionsCode.CanRemoveSharedUSer
            ])
          "
          :colspan="2"
          header="Actions"
          style="width: 11%"
        />
      </Row>
    </ColumnGroup>
    <Column field="shared_user_email" />
    <Column :field="(item) => item.access_level.name">
      <template #editor="{ data, field }">
        <Dropdown
          v-model="role"
          :options="sharedUserState.roles"
          class="w-full lg:w-[60%]"
          placeholder="Select a Role"
          v-bind="roleAttr"
          @change="generatePermissionsDetails"
        />
      </template>
    </Column>
    <Column field="status" />
    <Column v-if="hasPermissionCode([PermissionsCode.CanAddSharedUser])" :rowEditor="true" />
    <Column :exportable="false">
      <template #body="slotProps">
        <button
          class="p-2 rounded-full hover:bg-surface-100"
          @click="onRemoveSharedUser(slotProps.data)"
          v-if="hasPermissionCode([PermissionsCode.CanRemoveSharedUSer])"
        >
          <TrashIcon class="w-5 stroke-1.5" />
        </button>
      </template>
    </Column>
  </DataTable>
  <!--  END Shared User Data Table Component -->
</template>

<style lang="postcss" scoped></style>
