<script lang="ts" setup>
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import AuthenticationCard from '@/components/Card/AuthenticationCard.vue'
import { useResendOTP } from '@/services/authentication/useResendOTP'
import { useToast } from 'primevue/usetoast'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import EmailVerificationCard from '@/components/Card/EmailVerificationCard.vue'
import ForgetPasswordCard from '@/components/Card/ForgetPasswordCard.vue'
import router from '@/router'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { useHeadTag } from '@/utils/useHeadTag'

const { defineField, handleSubmit, errors, meta } = useForm({
  validationSchema: yup.object({
    email: yup.string().required().email().label('email')
  })
})

const toast = useToast()
const [email, emailAttr] = defineField('email')

// Email Verification
const showSendEmailOTPCard = ref<boolean>(true)
const showEmailVerificationCard = ref<boolean>(false)
const showForgetPasswordCard = ref<boolean>(false)

const onEmailSubmit = handleSubmit((values) => {
  useResendOTP(values.email).then(({ data, status }) => {
    if (status.value == 200) {
      showSendEmailOTPCard.value = false
      showEmailVerificationCard.value = true
      toast.add({
        severity: 'success',
        summary: 'Success Message',
        detail: data.value?.message,
        life: 10000
      })
    }
    if (status.value == 429) {
      toast.add({
        severity: 'warn',
        summary: 'Warning Message',
        detail: 'Attempt limit exceeded. Try again after a minute',
        life: 7000
      })
    }
  })
})

const { addTag, removeTag } = useHeadTag()
onMounted(() => {
  addTag('meta', 'name', 'robots', 'content', 'noindex, nofollow')
})
onBeforeUnmount(() => {
  removeTag('meta', 'robots', 'noindex, nofollow')
})
</script>

<template>
  <main class="flex flex-col justify-center items-center h-screen space-y-6">
    <!-- QR Mark Logo-->
    <picture>
      <source srcset="@/assets/img/qrmark-logo-full-text.webp" type="image/webp" />
      <img alt="QR Mark Logo" src="@/assets/img/qrmark-logo-full-text.svg" width="200" />
    </picture>

    <!--Email Submit Card-->
    <AuthenticationCard v-if="showSendEmailOTPCard">
      <template #header>
        <span class="flex pl-5 py-5 cursor-pointer" @click="router.push({ name: 'signin' })">
          <ChevronLeftIcon class="w-6 text-font-main" />
          <span class="text-font-main text-md">Back</span>
        </span>
      </template>
      <template #title>Forget Password?</template>
      <template #content>
        <form @submit="onEmailSubmit">
          <div class="mt-2 space-y-2">
            <span class="text-font-light text-lg font-jost">
              Enter your registered email address
            </span>
            <InputText
              id="email"
              v-model="email"
              placeholder="Email"
              size="small"
              type="email"
              v-bind="emailAttr"
            />
            <InlineMessage v-show="errors.email" severity="error">{{ errors.email }}</InlineMessage>
          </div>
          <Button
            :disabled="!meta.valid"
            class="w-full mt-10"
            label="Continue"
            outlined
            severity="success"
            type="submit"
          />
        </form>
      </template>
    </AuthenticationCard>

    <!--OTP submit Card-->
    <EmailVerificationCard
      v-if="showEmailVerificationCard"
      :email="email"
      source="forget_password"
      @navigate-back="
        () => {
          showSendEmailOTPCard = true
          showEmailVerificationCard = false
        }
      "
      @email-verification-completed="
        () => {
          showForgetPasswordCard = true
          showEmailVerificationCard = false
        }
      "
    />

    <!--Reset password card-->
    <ForgetPasswordCard
      v-if="showForgetPasswordCard"
      :email="email"
      @password-reset-completed="router.push({ name: 'signin' })"
    />
  </main>
</template>

<style scoped></style>
