import useAxios from '@/utils/useAxios'

export const useGenerateVerificationImage = async (
  fileId: number,
  name: string,
  domain: string
) => {
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: '/qr/',
    method: 'POST',
    responseType: 'blob',
    data: {
      file_id: fileId,
      name: name,
      domain: domain,
      source: 1
    }
  })

  return { data, error, status }
}
