<script lang="ts" setup>
const googleWorkspaceURL = import.meta.env.VUE_GOOGLE_WORKSPACE_URL
const microsoftAppSourceURL = import.meta.env.VUE_MICROSOFT_APPSOURCE_URL
</script>

<template>
  <main class="mx-2 lg:mx-10">
    <h1 class="text-font-main text-xl font-semibold my-5">Integrations</h1>
    <!--Integration Card-->
    <Card class="h-[72vh]">
      <template #title>
        Integrate QR Mark and generate verifications directly in popular document editors
      </template>
      <template #content>
        <div class="lg:flex lg:justify-around lg:mt-16 space-x-10">
          <!--Google Workspace Addons-->
          <div>
            <a :href="googleWorkspaceURL" target="_blank">
              <div class="flex border-2 border-gray-200 rounded-lg p-4">
                <div class="flex flex-wrap md:w-4/12">
                  <img alt="Google Docs" class="w-10" src="@/assets/img/google/GoogleDocs.svg" />
                  <img alt="Google Sheet" class="w-10" src="@/assets/img/google/GoogleSheets.svg" />
                  <img
                    alt="Google Slides"
                    class="w-10"
                    src="@/assets/img/google/GoogleSlides.svg"
                  />
                </div>
                <div class="space-y-3 px-3">
                  <div class="text-font-light text-xl font-semibold">Google Apps</div>
                  <div class="text-font-light font-normal">Integrate with Google apps</div>
                </div>
              </div>
            </a>
            <p class="text-font-light">
              <a
                class="underline"
                href="https://support.qrmark.com/hc/en-us/articles/36675827701273-Integrate-With-Google-Apps"
                target="_blank"
                >Click here</a
              >
              to learn about integration with Google apps.
            </p>
          </div>
          <!--Microsoft Add-ins -->
          <div>
            <a :href="microsoftAppSourceURL" target="_blank">
              <div class="p-3 lg:p-3 flex border-2 border-gray-200 rounded-lg">
                <div class="flex flex-wrap md:w-4/12">
                  <img alt="Microsoft Word" src="@/assets/img/microsoft/MsWord.svg" width="50" />
                  <img alt="Microsoft Excel" src="@/assets/img/microsoft/MsExcel.svg" width="50" />
                  <img
                    alt="Microsoft Power Point"
                    src="@/assets/img/microsoft/MsPowerPoint.svg"
                    width="50"
                  />
                </div>
                <div class="space-y-2 lg:px-4">
                  <div class="text-font-light text-xl font-semibold">Microsoft Office</div>
                  <div class="text-font-light font-normal">Integrate with Microsoft apps</div>
                </div>
              </div>
            </a>
            <p class="text-font-light">
              <a
                class="underline"
                href="https://support.qrmark.com/hc/en-us/articles/36675956351385-Integrate-With-Microsoft-AppSource-App"
                target="_blank"
                >Click here</a
              >
              to learn about integration with Microsoft apps.
            </p>
          </div>
        </div>
      </template>
    </Card>
  </main>
</template>

<style scoped></style>
