import useAxios from '@/utils/useAxios'

export const useBORequests = async (pageNumber: number) => {
  const { data, status, error, fetchData } = useAxios()
  await fetchData({
    url: '/bo/',
    method: 'GET',
    params: {
      page: pageNumber
    }
  })

  return { data, error, status }
}
