<script lang="ts" setup>
import { KeyIcon } from '@heroicons/vue/24/outline'
import { useRouter } from 'vue-router'

const router = useRouter()
</script>

<template>
  <Button
    class="flex items-center text-font-main w-full"
    icon-pos="left"
    label="Single sign-on (SSO)"
    outlined
    severity="secondary"
    @click="router.push({ name: 'sso' })"
  >
    <template #icon>
      <KeyIcon class="w-6 h-6 mr-4" />
    </template>
  </Button>
</template>

<style scoped></style>
