import useAxios from '@/utils/useAxios'

export const useSSORedirect = async (email: string) => {
  const { data, error, status, fetchData } = useAxios<{ auth_url?: string; message?: string }>()
  await fetchData({
    url: 'auth/sso/redirect/',
    method: 'POST',
    data: { email: email }
  })
  return { data, status, error }
}
