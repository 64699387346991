<script setup lang="ts">
import * as yup from 'yup'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import { useForm } from 'vee-validate'
import { type Router, useRouter } from 'vue-router'
import { useAuthToken } from '@/services/authentication/useAuthToken'
import { useFetchProfile } from '@/services/account/useFetchProfile'
import { LockClosedIcon } from '@heroicons/vue/24/outline'
import Password from 'primevue/password'
import Divider from 'primevue/divider'
import ContinueWithGoogle from '@/components/Button/ContinueWithGoogle.vue'
import AuthenticationCard from '@/components/Card/AuthenticationCard.vue'
import EmailVerificationCard from '@/components/Card/EmailVerificationCard.vue'
import { useTitle } from '@vueuse/core'
import { useHeadTag } from '@/utils/useHeadTag'
import ContinueWithSSO from '@/components/Button/ContinueWithSSO.vue'
import { useToast } from 'primevue/usetoast'

// Vee Validation
// The code `const { defineField, handleSubmit, errors, meta } = useForm({ validationSchema: { email:
// yup.string().required().email().label('Email'), password:
// yup.string().required().min(8).label('Password') } })` is using the `useForm` function from the
// `vee-validate` library to define form fields and handle form submission.
const { defineField, handleSubmit, errors, meta, setErrors } = useForm({
  validationSchema: yup.object({
    email: yup.string().required().email().label('Email'),
    password: yup.string().required().min(8).label('Password')
  })
})

// The code `const [password] = defineField('password')` and `const [email] = defineField('email')` is
// using the `defineField` function from the `vee-validate` library to define form fields for password
// and email.
const [password, passwordAttrs] = defineField('password')
const [email, emailAttrs] = defineField('email')

// Email Verification
const showEmailVerificationCard = ref<boolean>(false)
const isLoading = ref<boolean>(false)

const router: Router = useRouter()
const toast = useToast()

// Update Title
const title = useTitle()
title.value = 'QR Mark Sign In for Easy Documents Security'

// The `const onSubmit` is a function that is called when the form is submitted. It uses the
// `handleSubmit` function from the `vee-validate` library to handle form submission.
const onSubmit = handleSubmit((values) => {
  isLoading.value = true
  useAuthToken(values.email, values.password).then(({ data, status, error }) => {
    if (status.value == 200) {
      // Fetch profile details after token generation.
      useFetchProfile(data.value?.access_token).then(({ status }) => {
        // Navigate to the dashboard page.
        if (status.value == 200) {
          router.replace({ name: 'dashboard' })
          isLoading.value = false
        }
      })
    }
    if (status.value == 400) {
      if (error.value?.error == 'invalid_grant')
        setErrors({ password: error.value?.error_description })
      if (error.value?.error == 'email_verification_failed') {
        showEmailVerificationCard.value = true
      }
      isLoading.value = false
    }
    if (status.value == 429) {
      isLoading.value = false
      toast.add({
        severity: 'warn',
        summary: 'Warning Message',
        detail: 'Attempt limit exceeded. Try again after a minute',
        life: 7000
      })
    }
  })
})

// Marketing changes.

const { addTag, updateTag, removeTag } = useHeadTag()

onMounted(() => {
  addTag('meta', 'property', 'og:locale', 'content', 'en_us')
  addTag('meta', 'property', 'og:type', 'content', 'website')
  addTag('meta', 'property', 'og:site_name', 'content', 'QR Mark')
  addTag('meta', 'property', 'og:url', 'content', 'https://app.qrmark.com/signin')
  addTag('meta', 'property', 'og:title', 'content', 'QR Mark Sign in for Easy Documents Security')
  addTag(
    'meta',
    'property',
    'og:description',
    'content',
    'Sign in to your QR Mark account and resume securing your documents now.'
  )
  addTag(
    'meta',
    'property',
    'og:image',
    'content',
    'https://qrmark-production.s3.us-west-1.amazonaws.com/media/logo/qrmark-full-text-logo.png'
  )
  addTag('link', 'rel', 'canonical', 'href', 'https://app.qrmark.com/signin')
  addTag('meta', 'name', 'keywords', 'content', 'QR Mark Sign in, QR Mark Log in')
  updateTag(
    'meta',
    'description',
    'Sign in to your QR Mark account and resume securing your documents now'
  )
})
onBeforeUnmount(() => {
  removeTag('link', 'rel', 'canonical')
  removeTag('meta', 'name', 'keywords')
  updateTag(
    'meta',
    'description',
    'Prevent document counterfeiting by adding a layer of QR Code verification'
  )
})
</script>

<template>
  <div class="flex flex-col justify-center items-center h-screen space-y-6">
    <!-- QR Mark Logo-->
    <a href="https://qrmark.com">
      <picture>
        <source rel="preload" srcset="@/assets/img/qrmark-logo-full-text.webp" type="image/webp" />
        <img alt="QR Mark Logo" src="@/assets/img/qrmark-logo-full-text.svg" width="200" />
      </picture>
    </a>

    <!-- Signin Card -->
    <AuthenticationCard v-show="!showEmailVerificationCard">
      <template #title><h1>Sign in to your account</h1></template>
      <template #content>
        <div class="space-y-4">
          <ContinueWithGoogle />
          <ContinueWithSSO />
        </div>
        <Divider layout="horizontal" align="center" type="solid">or</Divider>

        <!-- Sign In Form -->
        <form class="space-y-4" @submit="onSubmit">
          <div>
            <div class="flex items-center">
              <InputText
                v-model="email"
                v-bind="emailAttrs"
                id="email"
                type="email"
                size="small"
                placeholder="Email"
              />
              <LockClosedIcon class="w-5 h-5 mx-2" />
            </div>
            <InlineMessage v-show="errors.email" severity="error">{{ errors.email }}</InlineMessage>
          </div>
          <div>
            <div class="flex items-center">
              <Password
                v-model="password"
                v-bind="passwordAttrs"
                id="password"
                type="password"
                class="w-full"
                placeholder="Password"
                toggle-mask
                :feedback="false"
                aria-label="Password"
                aria-labelledby="password"
              />
              <LockClosedIcon class="w-5 h-5 mx-2 text-font-main" />
            </div>
            <InlineMessage v-show="errors.password" severity="error">
              {{ errors.password }}
            </InlineMessage>
          </div>
          <!--Signin Button -->
          <Button
            type="submit"
            label="Sign In"
            severity="success"
            class="w-full"
            outlined
            :disabled="!meta.valid"
            :loading="isLoading"
          />
        </form>
        <span class="flex justify-center mt-5">
          <router-link :to="{ name: 'forget-password' }" class="text-secondary-light"
            >Forgot your password?
          </router-link>
        </span>
        <span class="flex justify-center mt-5 text-font-light text-sm">
          New User?
          <router-link :to="{ name: 'signup' }" class="text-secondary-light px-2">
            Create Account</router-link
          >
        </span>
      </template>
    </AuthenticationCard>
    <!--    Email Verification Card-->
    <EmailVerificationCard
      v-if="showEmailVerificationCard"
      :email="email"
      source="email_verification"
      @navigate-back="showEmailVerificationCard = false"
      @email-verification-completed="showEmailVerificationCard = false"
    />
  </div>
</template>

<style scoped lang="postcss"></style>
