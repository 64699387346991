<script lang="ts" setup>
import * as yup from 'yup'
import Dialog from 'primevue/dialog'

import { reactive } from 'vue'
import { useForm } from 'vee-validate'
import { useDeleteAccount } from '@/services/account/useDeleteAccount'
import { useToast } from 'primevue/usetoast'
import { useAuthStore } from '@/stores/authentication'

const toast = useToast()
const authStore = useAuthStore()

const context = reactive({
  visible: false,
  is_loading: false,
  options: [
    'The product is complex and difficult to use',
    'I don’t have time to use this product',
    'I’m concerned about my document safety',
    'Others'
  ]
})

const { defineField, handleSubmit, errors, meta } = useForm({
  validationSchema: {
    reason: yup.string().required('Please select the reason').label('reason'),
    feedback: yup.string().label('feedback')
  }
})

const [reason, reasonAttr] = defineField('reason')
const [feedback, feedbackAttr] = defineField('feedback')

const onHandleProceed = handleSubmit((values) => {
  context.is_loading = true
  useDeleteAccount(values.reason, values.feedback).then(({ data, status }) => {
    if (status.value == 204) {
      context.visible = false
      context.is_loading = false
      authStore.$reset()
      toast.add({
        severity: 'success',
        summary: 'Success Message',
        detail: data.value?.message,
        life: 7000
      })
    }
  })
})

const dialogPT = {
  root: ({ state: e }: any) => ({
    class: [
      'rounded-lg',
      'shadow-lg',
      'border-0',
      'max-h-[90vh]',
      'w-[50vw]',
      'm-0',
      'dark:border',
      'dark:border-surface-700',
      'transform',
      'scale-100',
      {
        'transition-none': e.maximized,
        'transform-none': e.maximized,
        '!w-screen': e.maximized,
        '!h-screen': e.maximized,
        '!max-h-full': e.maximized,
        '!top-0': e.maximized,
        '!left-0': e.maximized
      }
    ]
  }),
  header: {
    class: [
      'flex items-center justify-between',
      'shrink-0',
      'py-2 px-5',
      'border-t-0',
      'rounded-tl-lg',
      'rounded-tr-lg',
      'bg-surface-50 dark:bg-surface-800',
      'text-surface-700 dark:text-surface-0/80'
    ]
  },
  title: {
    class: ['font-bold text-lg']
  },
  icons: {
    class: ['flex items-center']
  },
  closeButton: {
    class: [
      'relative',
      'flex items-center justify-center',
      'mr-2',
      'last:mr-0',
      'w-8 h-8',
      'border-0',
      'rounded-full',
      'text-surface-500',
      'bg-transparent',
      'transition duration-200 ease-in-out',
      'hover:text-surface-700 dark:hover:text-white/80',
      'hover:bg-surface-200 dark:hover:bg-surface-800/80',
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
      'focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
      'overflow-hidden'
    ]
  },
  maximizablebutton: {
    class: [
      'relative',
      'flex items-center justify-center',
      'mr-2',
      'last:mr-0',
      'w-8 h-8',
      'border-0',
      'rounded-full',
      'text-surface-500',
      'bg-transparent',
      'transition duration-200 ease-in-out',
      'hover:text-surface-700 dark:hover:text-white/80',
      'hover:bg-surface-100 dark:hover:bg-surface-800/80',
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
      'focus:ring-primary-400/50 dark:focus:ring-primary-300/50',
      'overflow-hidden'
    ]
  },
  closeButtonIcon: {
    class: ['inline-block', 'w-4', 'h-4']
  },
  maximizableicon: {
    class: ['inline-block', 'w-4', 'h-4']
  },
  content: ({ state: e, instance: r }: any) => ({
    class: [
      'px-6',
      'pb-8',
      'pt-4',
      { grow: e.maximized, 'rounded-bl-lg': !r.$slots.footer, 'rounded-br-lg': !r.$slots.footer },
      'bg-surface-0 dark:bg-surface-800',
      'text-surface-700 dark:text-surface-0/80',
      'overflow-y-auto'
    ]
  }),
  footer: {
    class: [
      'flex items-center justify-end',
      'shrink-0',
      'text-right',
      'gap-2',
      'px-6',
      'pb-6',
      'border-t-0',
      'rounded-b-lg',
      'bg-surface-0 dark:bg-surface-800',
      'text-surface-700 dark:text-surface-0/80'
    ]
  },
  mask: ({ props: e }: any) => ({
    class: [
      'transition-all',
      'duration-300',
      { 'p-5': e.position != 'full' },
      {
        'has-[.mask-active]:bg-transparent bg-black/40': e.modal,
        'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': e.modal
      }
    ]
  }),
  transition: ({ props: e }: any) =>
    e.position === 'top'
      ? {
          enterFromClass:
            'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass:
            'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active'
        }
      : e.position === 'bottom'
        ? {
            enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass:
              'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active'
          }
        : e.position === 'left' || e.position === 'topleft' || e.position === 'bottomleft'
          ? {
              enterFromClass:
                'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active'
            }
          : e.position === 'right' || e.position === 'topright' || e.position === 'bottomright'
            ? {
                enterFromClass:
                  'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass:
                  'opacity-0 scale-75 opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active'
              }
            : {
                enterFromClass: 'opacity-0 scale-75 mask-active',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass: 'opacity-0 scale-75 mask-active'
              }
}
</script>

<template>
  <div class="space-y-4">
    <p>
      Use this option to permanently delete your account and all linked verifications data. Please
      note that this cannot be undone.
    </p>

    <p class="text-font-light text-sm">
      Need help with your account? Learn how to
      <a
        class="underline"
        href="https://support.qrmark.com/hc/en-us/articles/36685832516889-Reset-Password"
        target="_blank"
        >reset your password</a
      >
      or
      <a
        class="underline"
        href="https://support.qrmark.com/hc/en-us/articles/36685625492633-Edit-Registered-Email-Address"
        target="_blank"
        >change your email address</a
      >
      by following our support guides.
    </p>

    <Button
      label="Delete Account"
      outlined
      severity="danger"
      type="button"
      @click="context.visible = true"
    />
  </div>

  <Dialog
    v-model:visible="context.visible"
    :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
    :pt="dialogPT"
    :style="{ width: '50rem' }"
    header="Header"
    modal
  >
    <template #header>
      <div class="inline-flex items-center justify-center gap-2">
        <span class="font-bold white-space-nowrap">Delete Account</span>
      </div>
    </template>
    <div class="m-0 space-y-5">
      <div class="space-y-2">
        <span class="text-lg font-medium text-font-main">
          Let us know the reason for deleting your QR Mark account
        </span>
        <Dropdown
          v-model="reason"
          :options="context.options"
          placeholder="Please select the reason"
          v-bind="reasonAttr"
        />
        <InlineMessage v-show="errors.reason" severity="error">
          {{ errors.reason }}
        </InlineMessage>
      </div>
      <div class="space-y-2">
        <span class="text-lg font-medium text-font-main">Feedback</span>
        <Textarea
          v-model="feedback"
          class="w-full"
          placeholder="We value your feedback. Please tell us how we can improve our product"
          rows="5"
          v-bind="feedbackAttr"
        />
      </div>
    </div>
    <template #footer>
      <div class="space-x-4">
        <Button
          label="Cancel"
          outlined
          severity="danger"
          size="small"
          type="button"
          @click="context.visible = false"
          :disabled="context.is_loading"
        />
        <Button
          label="Proceed"
          outlined
          severity="success"
          size="small"
          type="button"
          :disabled="!meta.valid"
          :loading="context.is_loading"
          @click="onHandleProceed"
        />
      </div>
    </template>
  </Dialog>
</template>

<style scoped></style>
