<script setup lang="ts">
import { ArrowRightOnRectangleIcon } from '@heroicons/vue/24/outline'
import { useRevokeToken } from '@/services/authentication/useRevokeToken'
import { useAuthStore } from '@/stores/authentication'

const authStore = useAuthStore()
</script>

<template>
  <div class="flex bg-white justify-end p-4">
    <span v-if="authStore.getUserEmail" class="px-4 text-font-light border-gray-300 border-r-2">{{
      authStore.getUserEmail
    }}</span>
    <div class="px-5 cursor-pointer" @click="useRevokeToken">
      <ArrowRightOnRectangleIcon class="w-6" />
    </div>
  </div>
  <hr />
</template>

<style scoped></style>
