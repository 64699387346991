<script lang="ts" setup></script>

<template>
  <!--Loading dots-->
  <div class="flex space-x-4 justify-center items-center bg-white dark:invert">
    <span class="sr-only">Loading...</span>
    <div
      class="h-4 w-4 bg-primary-500 ring-offset-2 ring-2 ring-primary-500 rounded-full animate-bounce [animation-delay:-0.3s]"
    ></div>
    <div
      class="h-4 w-4 bg-primary-500 ring-offset-2 ring-2 ring-primary-500 rounded-full animate-bounce [animation-delay:-0.15s]"
    ></div>
    <div
      class="h-4 w-4 bg-primary-500 ring-offset-2 ring-2 ring-primary-500 rounded-full animate-bounce"
    ></div>
  </div>
</template>

<style scoped></style>
