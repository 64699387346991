<script lang="ts" setup>
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import AuthenticationCard from '@/components/Card/AuthenticationCard.vue'
import { useToast } from 'primevue/usetoast'
import { onBeforeUnmount, onMounted, ref } from 'vue'
import router from '@/router'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { useSSORedirect } from '@/services/authentication/useSSORedirect'
import { useRoute } from 'vue-router'
import { useAuthStore } from '@/stores/authentication'
import { useSSOToken } from '@/services/authentication/useSSOToken'
import { useFetchProfile } from '@/services/account/useFetchProfile'

const { defineField, handleSubmit, errors, meta, setErrors } = useForm({
  validationSchema: yup.object({
    email: yup.string().required().email().label('email')
  })
})

const toast = useToast()
const route = useRoute()
const authStore = useAuthStore()

const isLoading = ref<boolean>(false)
const [email, emailAttr] = defineField('email')

const onEmailSubmit = handleSubmit((values) => {
  isLoading.value = true
  useSSORedirect(values.email).then(({ data, status, error }) => {
    if (status.value == 200) {
      authStore.setUserEmail(values.email)
      console.log(data.value?.auth_url)
      // @ts-ignore
      window.open(data.value?.auth_url, '_self').focus()
    }
    if (status.value == 400) {
      isLoading.value = false
      toast.add({
        severity: 'error',
        summary: 'SSO Setup not found. ',
        detail: error.value?.details.message,
        life: 8000
      })
    }
    if (status.value == 500) {
      isLoading.value = false
      toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Something went wrong. Please reach out to us at support@qrmark.com ',
        life: 7000
      })
    }
    if (status.value == 429) {
      isLoading.value = false
      toast.add({
        severity: 'warn',
        summary: 'Warning Message',
        detail: 'Attempt limit exceeded. Try again after a minute',
        life: 7000
      })
    }
  })
})

const convertSSOToken = (code: string) => {
  isLoading.value = true
  useSSOToken(<string>authStore.getUserEmail, code).then(({ data, status, error }) => {
    if (status.value == 200) {
      // Fetch profile details after token generation.
      useFetchProfile(data.value?.access_token).then(({ status }) => {
        // Navigate to the dashboard page.
        if (status.value == 200) {
          router.replace({ name: 'dashboard' })
          isLoading.value = false
        }
      })
    }
    if (status.value == 400) {
      if (error.value?.error == 'invalid_grant') isLoading.value = false
      setErrors({ email: error.value?.error_description })
    }
  })
}

const createNoIndexNoFollowMetaTag = () => {
  const metaTag = document.createElement('meta')
  metaTag.name = 'robots'
  metaTag.content = 'noindex, nofollow'
  document.head.appendChild(metaTag)
}
const removeMetaTagByName = (name: string) => {
  const metaTags = document.head.querySelectorAll(`meta[name="${name}"]`)
  metaTags.forEach((tag) => tag.remove())
}

onMounted(() => {
  createNoIndexNoFollowMetaTag()
  route.query.code ? convertSSOToken(<string>route.query.code) : null
})
onBeforeUnmount(() => {
  removeMetaTagByName('robots')
})
</script>

<template>
  <main class="flex flex-col justify-center items-center h-screen space-y-6">
    <!-- QR Mark Logo-->
    <picture>
      <source srcset="@/assets/img/qrmark-logo-full-text.webp" type="image/webp" />
      <img alt="QR Mark Logo" src="@/assets/img/qrmark-logo-full-text.svg" width="200" />
    </picture>

    <!--Email Submit Card-->
    <AuthenticationCard>
      <template #header>
        <span class="flex pl-5 py-5 cursor-pointer" @click="router.go(-1)">
          <ChevronLeftIcon class="w-6 text-font-main" />
          <span class="text-font-main text-md">Back</span>
        </span>
      </template>
      <template #title><h1>Continue with SSO</h1></template>
      <template #content>
        <form @submit="onEmailSubmit">
          <div class="mt-2 space-y-2">
            <span class="text-font-light text-lg font-jost">
              Enter your organisation email address
            </span>
            <InputText
              id="email"
              v-model="email"
              placeholder="Email"
              size="small"
              type="email"
              v-bind="emailAttr"
            />
            <InlineMessage v-show="errors.email" severity="error">{{ errors.email }}</InlineMessage>
          </div>
          <Button
            :disabled="!meta.valid"
            :loading="isLoading"
            class="w-full mt-10"
            label="Continue"
            outlined
            severity="success"
            type="submit"
          />
        </form>
      </template>
    </AuthenticationCard>
  </main>
</template>

<style scoped></style>
