<script lang="ts" setup>
import AuthenticationCard from '@/components/Card/AuthenticationCard.vue'
import { ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { onOTPInput, onOTPInputKeyDown, onOtpPaste } from '@/utils/helpers/otp-input'
import { onMounted, ref } from 'vue'
import { useEmailVerification } from '@/services/authentication/useEmailVerification'
import { useToast } from 'primevue/usetoast'
import { useResendOTP } from '@/services/authentication/useResendOTP'

const toast = useToast()

const store = ref(Array(6).fill(''))
const otpError = ref({ error_msg: '' })

// Props definition
// source: from where the user requested.
const props = defineProps<{
  email: string
  source: 'signup' | 'forget_password' | 'email_verification'
}>()

const emit = defineEmits<{
  (event: 'NavigateBack'): void
  (event: 'EmailVerificationCompleted'): void
}>()

const onOTPSubmit = () => {
  const otp = parseInt(store.value.join(''))
  useEmailVerification(props.email, otp, props.source).then(({ data, status, error }) => {
    if (status.value == 200) {
      toast.add({
        severity: 'success',
        summary: 'Success Message',
        detail: data.value?.message,
        life: 7000
      })
      emit('EmailVerificationCompleted')
    }
    if (status.value == 400) otpError.value.error_msg = error.value?.details?.detail
    if (status.value == 429) {
      toast.add({
        severity: 'warn',
        summary: 'Warning Message',
        detail: 'Attempt limit exceeded. Try again after a minute',
        life: 7000
      })
    }
  })
}

const countdown = ref(10)

const startCountdown = () => {
  const countdownInterval = setInterval(() => {
    countdown.value--
    if (countdown.value === 0) {
      clearInterval(countdownInterval)
      countdown.value = 10
    }
  }, 1000)
}

const resendOTP = () => {
  useResendOTP(props.email).then(({ data, status }) => {
    if (status.value == 200) {
      startCountdown()
      toast.add({
        severity: 'success',
        summary: 'Success Message',
        detail: data.value?.message,
        life: 10000
      })
    }
  })
}

onMounted(() => {
  let element = document.getElementById('inputContainer')
  element?.addEventListener('paste', (event: ClipboardEvent) => {
    onOtpPaste(event, store)
  })
})
</script>

<template>
  <AuthenticationCard>
    <template #header>
      <span class="flex pl-5 py-5 cursor-pointer" @click="emit('NavigateBack')">
        <ChevronLeftIcon class="w-6 text-font-main" />
        <span class="text-font-main text-md">Back</span>
      </span>
    </template>
    <template #title>Verify Email Address</template>
    <template #content>
      <p class="text-font-light text-sm">
        Enter the 6-digit code sent to {{ props.email }}. Check Spam folder if you haven't received
        it.
      </p>
      <form class="text-center" @submit.prevent="onOTPSubmit">
        <div id="inputContainer" class="mt-5">
          <input
            v-for="(input, index) in store"
            :id="'otpInput-' + index"
            v-model="store[index]"
            :maxlength="1"
            class="border-2 border-gray-400 rounded w-10 m-2 p-2 focus:border-green-500 focus:outline-none"
            type="text"
            @input="onOTPInput($event, index, store, otpError)"
            @keydown="onOTPInputKeyDown($event, index, store, otpError)"
          />
          <p v-if="otpError.error_msg" class="text-red-500 text-sm">
            {{ otpError.error_msg }}
          </p>
        </div>
        <div class="flex justify-center ml-52">
          <span class="mr-2 text-font-main">{{ countdown }}s</span>
          <button
            :disabled="countdown != 10"
            class="text-font-main underline cursor-pointer disabled:opacity-25 disabled:cursor-not-allowed"
            type="button"
            @click="resendOTP"
          >
            Resend Code
          </button>
        </div>
        <Button class="w-full mt-5" label="Verify" outlined size="small" type="submit" />
      </form>
    </template>
  </AuthenticationCard>
</template>

<style scoped></style>
