import useAxios from '@/utils/useAxios'

export const useUpdateSharedUser = async (sharedUserId: number, accessLevelId: number) => {
  const { data, error, status, fetchData } = useAxios<any>()

  // Update Shared User access level
  await fetchData({
    url: `/multi-user/${sharedUserId}/`,
    method: 'PATCH',
    data: {
      access_level_id: accessLevelId
    }
  })
  return { data, error, status }
}
