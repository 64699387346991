import { defineStore } from 'pinia'
import axios from 'axios'
import router from '@/router'

export interface OwnerUserDetailsInterface {
  first_name: string
  email: string
}

export interface SharedUserAccessInterface {
  id: number
  name: string
  permissions: string[]
  created_by: null | string
}
export interface AuthStoreInterface {
  email: string | null
  accessToken: string | null
  refreshToken: string | null
  expiresIn: number | null
  showOnboardingScene: boolean
  fullName: string | null
  isSharedUser: boolean
  sharedUserAccess: SharedUserAccessInterface | null
  ownerUserDetails: OwnerUserDetailsInterface | null
}

export const useAuthStore = defineStore('authUser', {
  state: (): AuthStoreInterface => ({
    email: null,
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    showOnboardingScene: false,
    fullName: null,
    isSharedUser: false,
    sharedUserAccess: null,
    ownerUserDetails: null
  }),

  getters: {
    getUserEmail: (state: AuthStoreInterface): string | null => {
      return state.email || (state.email = localStorage.getItem('email'))
    },
    getAccessToken: (state: AuthStoreInterface): string | null => {
      return state.accessToken || (state.accessToken = localStorage.getItem('accessToken'))
    },
    getRefreshToken: (state: AuthStoreInterface): string | null => {
      return state.refreshToken || (state.refreshToken = localStorage.getItem('refreshToken'))
    },
    getExpiresIn: (state: AuthStoreInterface): Number => {
      return state.expiresIn || (state.expiresIn = Number(localStorage.getItem('expiresIn')))
    },
    getShowOnboardingScene: (state: AuthStoreInterface): boolean => {
      return state.showOnboardingScene
    },
    getFullName: (state: AuthStoreInterface): string | null => {
      return state.fullName || (state.fullName = localStorage.getItem('fullName'))
    },
    getIsSharedUser: (state: AuthStoreInterface): boolean => {
      return (
        state.isSharedUser ||
        (state.isSharedUser = Boolean(Number(localStorage.getItem('isSharedUser'))))
      )
    },
    getOwnerUserDetails: (state: AuthStoreInterface): OwnerUserDetailsInterface => {
      return (
        state.ownerUserDetails ||
        (state.ownerUserDetails = JSON.parse(localStorage.getItem('ownerUserDetails') || 'null'))
      )
    },
    getSharedUserAccess: (state: AuthStoreInterface): object => {
      return (
        state.sharedUserAccess ||
        (state.sharedUserAccess = JSON.parse(localStorage.getItem('sharedUserAccess') || 'null'))
      )
    }
  },

  actions: {
    setUserEmail(email: string): void {
      email && (this.email = email)
      this.email && localStorage.setItem('email', this.email)
    },
    setAccessToken(accessToken: string): void {
      // Update the state of access token
      accessToken && (this.accessToken = accessToken)
      // persist the value of access token in local storage.
      this.accessToken && localStorage.setItem('accessToken', this.accessToken)
    },
    setRefreshToken(refreshToken: string): void {
      refreshToken && (this.refreshToken = refreshToken)
      this.refreshToken && localStorage.setItem('refreshToken', this.refreshToken)
    },
    setExpiresIn(): void {
      // Refresh token expires in
      this.expiresIn = Date.now() + 48 * 60 * 60 * 1000
      this.expiresIn && localStorage.setItem('expiresIn', this.expiresIn.toString())
    },
    isAuthenticated(): boolean {
      return !!(this.getAccessToken && Date.now() < Number(this.getExpiresIn))
    },
    setShowOnboardingScene(value: boolean): boolean {
      return (this.showOnboardingScene = value)
    },
    setFullName(fullName: string): void {
      // Update state of full name
      fullName && (this.fullName = fullName)
      // Persist the value of full name in local storage.
      this.fullName && localStorage.setItem('fullName', this.fullName)
    },
    setIsSharedUser(value: boolean): void {
      value && (this.isSharedUser = value)
      this.isSharedUser && localStorage.setItem('isSharedUser', String(Number(value)))
    },
    setOwnerUserDetails(ownerUserDetails: OwnerUserDetailsInterface): void {
      ownerUserDetails && (this.ownerUserDetails = ownerUserDetails)
      this.ownerUserDetails &&
        localStorage.setItem('ownerUserDetails', JSON.stringify(ownerUserDetails))
    },
    setSharedUserAccess(access: SharedUserAccessInterface | null): void {
      access && (this.sharedUserAccess = access)
      this.sharedUserAccess && localStorage.setItem('sharedUserAccess', JSON.stringify(access))
    },
    $reset(): void {
      this.email = null
      this.accessToken = null
      this.refreshToken = null
      this.expiresIn = null
      this.showOnboardingScene = false
      this.fullName = null
      this.isSharedUser = false
      this.sharedUserAccess = null
      this.ownerUserDetails = null

      // Clear the local storage on reset
      localStorage.clear()

      // Dispose store
      this.$dispose()

      // Remove the authorization header from the axios
      delete axios.defaults.headers.common['Authorization']

      // Navigate to signin page
      router.replace({ name: 'signin' }).then((r) => {})
    }
  }
})
