import useAxios from '@/utils/useAxios'

export const useExportPDF = async (fileId: number, info: object, metadata: object) => {
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: '/pdf/export/',
    method: 'POST',
    data: {
      original: fileId,
      info: info,
      metadata: metadata
    }
  })

  return { data, error, status }
}
