import { defineStore } from 'pinia'

interface VerificationStoreInterface {
  activeStep: number
  mediaId: number | null
  mediaURL: string | null
  mediaFileName: string | null
  mediaFileSize: number | null
}
export const useVerificationStore = defineStore('verification', {
  state: (): VerificationStoreInterface => ({
    activeStep: 0,
    mediaId: null,
    mediaURL: null,
    mediaFileName: null,
    mediaFileSize: null
  }),
  getters: {
    getActiveStep: (state: VerificationStoreInterface): number => {
      return state.activeStep
    },
    getMediaId: (state: VerificationStoreInterface): number | null => {
      return state.mediaId
    },
    getMediaFileName: (state: VerificationStoreInterface): string | null => {
      return state.mediaFileName
    },
    getMediaURL: (state: VerificationStoreInterface): string | null => {
      return state.mediaURL
    },
    getMediaFileSize: (state: VerificationStoreInterface): number | null => {
      return state.mediaFileSize
    }
  },
  actions: {
    setMediaID(id: number): void {
      this.mediaId = id
    },
    setMediaFileName(mediaFileName: string): void {
      this.mediaFileName = mediaFileName
    },
    setMediaURL(url: string): void {
      this.mediaURL = url
    },
    setMediaFileSize(size: number): void {
      this.mediaFileSize = size
    },
    setActiveStep(step: number): void {
      this.activeStep = step
    },

    $reset(): void {
      this.activeStep = 0
      this.mediaId = null
      this.mediaURL = null
      this.mediaFileName = null
      this.mediaFileSize = null
      this.$dispose()
    }
  }
})
