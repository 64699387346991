<script setup lang="ts">
import Column from 'primevue/column'
import Sidebar from 'primevue/sidebar'
import DataTable from 'primevue/datatable'
import { useToast } from 'primevue/usetoast'
import { formatDate } from '@/utils/helpers'
import { onMounted, reactive, watch } from 'vue'
import { useConfirm } from 'primevue/useconfirm'
import { ArrowPathIcon, TrashIcon } from '@heroicons/vue/24/outline'
import CustomDomainSetup from '@/components/Accordion/CustomDomainAccordion.vue'
import { useCustomDomainList, useRemoveCustomDomain } from '@/services/customDomain'
import { useCustomDomainVerification } from '@/services/customDomain/useCustomDomainVerification'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import { useHasPermission } from '@/utils/useHasPermission'
import { PermissionsCode } from '@/constants/permissions'

const customDomainState = reactive({
  loading: false,
  visibleSidebar: false,
  pageNumber: 1,
  totalRecords: 0,
  pageRows: 5,
  customDomains: []
})

const toast = useToast()
const confirm = useConfirm()
const { hasPermissionCode } = useHasPermission()

const onPageChange = (event: any) => {
  customDomainState.pageNumber = event.page + 1
}

const fetchCustomDomainList = (pageNumber: number = 1) => {
  customDomainState.loading = true
  useCustomDomainList(pageNumber).then(({ data, error, status }) => {
    if (status.value == 200) {
      customDomainState.customDomains = data.value?.results
      customDomainState.totalRecords = data.value?.count
    }
  })
  customDomainState.loading = false
}

const onConfirmRemoveCustomDomain = (data: any) => {
  confirm.require({
    message: `Are you sure you want to remove the custom domain '${data.domain}'?`,
    header: 'Confirmation',
    acceptLabel: 'Confirm',
    rejectLabel: 'Cancel',
    rejectClass: 'bg-red-500 border-red-600 hover:bg-red-600 hover:border-red-600',
    accept: () => {
      useRemoveCustomDomain(data.id).then(({ status }) => {
        if (status.value == 204) {
          customDomainState.customDomains = customDomainState.customDomains.filter(
            (val: any) => val.id !== data.id
          )
          customDomainState.totalRecords -= 1
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Custom Domain deleted',
            life: 7000
          })
        }
      })
    },
    reject: () => {}
  })
}

const verifyCustomDomain = async (data: any) => {
  await useCustomDomainVerification(data.id).then(({ data, status, error }) => {
    if (status.value == 200) {
      toast.add({
        summary: 'Success Message',
        severity: 'success',
        detail: 'Custom domain verified successfully',
        life: 7000
      })
      fetchCustomDomainList(customDomainState.pageNumber)
    }
    if (status.value == 400) {
      toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: error.value?.details.detail,
        life: 7000
      })
    }
  })
}

// Watcher
watch(
  () => customDomainState.pageNumber,
  () => {
    fetchCustomDomainList(customDomainState.pageNumber)
  }
)

// On Mount
onMounted(() => {
  if (hasPermissionCode([PermissionsCode.CanViewCustomDomain])) fetchCustomDomainList()
})
</script>

<template>
  <div>
    <p class="text-font-main">
      Use this feature to customize the domain of verification links generated using QR Mark. Adding
      your own domain in verification links will make the verification trustworthy with end-users
      and avoid counterfeiting.
    </p>
    <button class="text-secondary-light underline" @click="customDomainState.visibleSidebar = true">
      Learn more about setting up a custom domain
    </button>
    <!--    SideBar Content-->
    <!--    TODO: Move this sidebar into different component-->
    <Sidebar v-model:visible="customDomainState.visibleSidebar" position="right">
      <template #header>
        <h2 class="text-font-main text-md font-bold subpixel-antialiased">
          What is a custom domain?
        </h2>
      </template>

      <p class="p-2 my-4 text-font-light">
        Use this feature to customize the domain of verification links generated using QR Mark.
        Adding your own domain in verification links will make the verification trustworthy with
        end-users and avoid counterfeiting. As a best practice, you can create and setup a
        sub-domain of your brand's root domain. For example, if your domain is brand.com, you can
        create and setup the sub-domain verify.brand.com or docs.brand.com Please following this
        step-by-step guide on how to setup a custom domain
      </p>
      <img
        src="@/assets/img/custom-domain/CustomDomainFrame.svg"
        alt="Custom Domain Frame"
        class="w-fit my-5"
      />
    </Sidebar>
    <!--    END Sidebar-->
    <div v-if="hasPermissionCode([PermissionsCode.CanAddCustomDomain])">
      <h3 class="text-font-main pl-2 text-md font-extrabold subpixel-antialiased my-3 lg:my-5">
        Setup Custom Domain
      </h3>
      <!--Custom Domain Setup Accordion-->
      <CustomDomainSetup
        @completed-domain-setup.once="fetchCustomDomainList(customDomainState.pageNumber)"
      />
    </div>

    <!--    Custom Domain List-->
    <h3
      v-if="hasPermissionCode([PermissionsCode.CanViewCustomDomain])"
      class="text-font-main pl-2 text-md font-extrabold subpixel-antialiased my-6"
    >
      Custom Domains
    </h3>
    <div class="shadow-md my-5">
      <DataTable
        :rows="customDomainState.pageRows"
        lazy
        stripedRows
        :value="customDomainState.customDomains"
        :loading="customDomainState.loading"
        :paginator="customDomainState.totalRecords > 0 ?? null"
        :total-records="customDomainState.totalRecords"
        data-key="id"
        size="small"
        @page="onPageChange($event)"
        v-if="hasPermissionCode([PermissionsCode.CanViewCustomDomain])"
      >
        <template #empty>
          <p class="text-center text-font-main">Get started by setup your first custom domain</p>
        </template>
        <template #loading>Loading... Please wait.</template>
        <ColumnGroup type="header">
          <Row>
            <Column header="Domain Nane" />
            <Column header="Status" style="width: 10%" />
            <Column header="Created By" />
            <Column header="Created On" style="width: 12%" />
            <Column :colspan="2" header="Actions" style="width: 11%" />
          </Row>
        </ColumnGroup>

        <Column field="domain" header="Domain Name" />
        <Column field="is_verified" header="Status">
          <template #body="{ data }">
            <span v-show="data?.is_verified">Verified</span>
            <span v-show="!data?.is_verified">Pending</span>
          </template>
        </Column>
        <Column field="created_by" header="Created By" />
        <Column field="created" header="Created On">
          <!--suppress JSUnresolvedReference -->
          <template #body="{ data }">
            {{ formatDate(data?.created) }}
          </template>
        </Column>
        <Column :exportable="false" header="Actions">
          <!-- TODO: Fix button user experience-->
          <template #body="{ data }">
            <button
              :class="data?.is_verified ? 'invisible' : 'visible'"
              class="action-button mr-4"
              @click.once="verifyCustomDomain(data)"
            >
              <ArrowPathIcon class="w-5 stroke-1.5" />
            </button>
            <button
              v-if="
                hasPermissionCode([
                  PermissionsCode.CanRemoveCustomDomain,
                  PermissionsCode.CanViewCustomDomain
                ])
              "
              class="action-button"
              @click="onConfirmRemoveCustomDomain(data)"
            >
              <TrashIcon class="w-5 stroke-1.5" />
            </button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.action-button {
  @apply p-2 rounded-full hover:bg-surface-100;
}
</style>
