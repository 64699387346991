import useAxios from '@/utils/useAxios'

export const useRemoveVerification = async (hash: string) => {
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: `/qr/remove/${hash}/`,
    method: 'DELETE'
  })
    .then(() => {
      console.log('Successfully delete the data')
    })
    .catch(() => {
      console.log('Exception occur while deleting the data', error)
    })
    .finally(() => {})
  return { data, error, status }
}
