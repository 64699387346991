<script setup lang="ts">
import { ref } from 'vue'
import { googleTokenLogin } from 'vue3-google-login'
import { useConvertToken } from '@/services/authentication'
import { useFetchProfile } from '@/services/account/useFetchProfile'
import { useToast } from 'primevue/usetoast'
import { type Router, useRoute, useRouter } from 'vue-router'

const isLoading = ref<boolean>(false)

const toast = useToast()

const router: Router = useRouter()
const route = useRoute()

const googleLoginCallback = () => {
  isLoading.value = true
  // Convert Token
  googleTokenLogin({ clientId: import.meta.env.VUE_GOOGLE_OAUTH_KEY })
    .then((response) => {
      // Convert Token
      useConvertToken(response.access_token).then(({ data, status, error }) => {
        if (status.value == 200) {
          // Fetch profile details after token generation after google login only.
          useFetchProfile(data.value?.access_token).then(({ data, status }) => {
            // Navigate to the dashboard page.
            if (status.value == 200) {
              if (route.name == 'signup' && import.meta.env.VUE_ENVIRONMENT === 'production') {
                // @ts-ignore: Property 'dataLayer' does not exist on type 'Window & typeof globalThis'.
                window.dataLayer.push({
                  event: 'signupCompletedEvent',
                  email: data.value?.email
                })
              }
              isLoading.value = false
              router.replace({ name: 'dashboard' })
            }
          })
        }
        if (status.value == 500) {
          toast.add({
            severity: 'error',
            summary: 'Error Message',
            detail: 'Something went wrong. Please reach out to us at support@qrmark.com ',
            life: 7000
          })
        }
      })
    })
    .catch()
    .finally()
}
</script>

<template>
  <Button
    :loading="isLoading"
    label="Continue with Google"
    icon-pos="left"
    severity="secondary"
    @click="googleLoginCallback"
    class="flex items-center text-font-main w-full"
    outlined
  >
    <template #icon>
      <img alt="Google Icon" class="h-6 w-6 mr-4" src="../../assets/img/google/GoogleLogo.svg" />
    </template>
  </Button>
</template>

<style scoped></style>
