import useAxios from '@/utils/useAxios'

export const useRegistration = async (name: string, email: string, password: string) => {
  // Access token endpoint.
  const { data, error, status, fetchData } = useAxios<any>()

  // Fetch Access token
  await fetchData({
    url: 'auth/register/',
    method: 'POST',
    data: {
      first_name: name,
      email: email,
      password: password
    }
  })
  return { status, error }
}
