import useAxios from '@/utils/useAxios'
import { useAuthStore } from '@/stores/authentication'
import type { AccessTokenInterface } from '@/services/authentication/'
import router from '@/router'
import axios from 'axios'

export const useUnauthorizedToken = () => {
  // Auth Store
  const authStore = useAuthStore()

  // Convert google logged in token to oauth token.
  const { data, error, status, fetchData } = useAxios<AccessTokenInterface>()

  // Remove the authorization header from the axios
  delete axios.defaults.headers.common['Authorization']

  // Fetch token response
  fetchData({
    url: 'auth/token/',
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: {
      grant_type: 'refresh_token',
      client_id: import.meta.env.VUE_API_CLIENT_ID,
      client_secret: import.meta.env.VUE_API_CLIENT_SECRET,
      refresh_token: authStore.getRefreshToken
    }
  })
    .then(() => {
      data.value?.access_token && authStore.setAccessToken(data.value?.access_token)
      data.value?.refresh_token && authStore.setRefreshToken(data.value?.refresh_token)
      authStore.setExpiresIn() // Set refresh token expiry time.

      // Set access token in the axios header.
      axios.defaults.headers.common['Authorization'] = `Bearer ${authStore.getAccessToken}`
      // Reload the current route to fetch the data again.
      router.go(0)
      if (status.value == 400) authStore.$reset()
      if (status.value == 429) authStore.$reset()
    })
    .catch(() => {})
    .finally(() => {
      console.debug('Successfully fetch refresh token')
    })
}
