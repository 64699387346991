import useAxios from '@/utils/useAxios'

export const useDeleteMedia = async (id: number | null) => {
  // Access token endpoint.
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: `/media/upload/${id}/`,
    method: 'DELETE'
  }).then(() => {
    console.log('Successfully deleted the uploaded file.')
  })

  return { status }
}
