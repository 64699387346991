<script setup lang="ts"></script>

<template>
  <!-- QR Mark Logo-->
  <div class="flex justify-center mt-16 mb-10">
    <img alt="QR Mark Logo" src="@/assets/img/qrmark-logo-full-text.svg" width="200" />
  </div>
  <h1 class="strong, text-center text-font-light">Page not found!</h1>
</template>

<style scoped></style>
