import useAxios from '@/utils/useAxios'

export const useFetchAccessLevel = async () => {
  const { data, error, status, fetchData } = useAxios<any>()

  // fetch access level
  await fetchData({
    url: '/multi-user/access-level/',
    method: 'GET'
  })

  return { data, error, status }
}
