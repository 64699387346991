import useAxios from '@/utils/useAxios'
import { useAuthStore } from '@/stores/authentication'
import type { AccessTokenInterface } from '@/services/authentication/index'

export const useSSOToken = async (email: string, code: string) => {
  // Auth Store
  const authStore = useAuthStore()

  // Convert google logged in token to oauth token.
  const { data, error, status, fetchData } = useAxios<AccessTokenInterface>()

  // Fetch token response
  await fetchData({
    url: 'auth/token/sso/',
    method: 'POST',
    data: {
      grant_type: 'sso_token',
      client_id: import.meta.env.VUE_API_CLIENT_ID,
      client_secret: import.meta.env.VUE_API_CLIENT_SECRET,
      email: email,
      code: code
    }
  })
    .then(() => {
      authStore.$reset()
      data.value?.access_token && authStore.setAccessToken(data.value?.access_token)
      data.value?.refresh_token && authStore.setRefreshToken(data.value?.refresh_token)
      authStore.setExpiresIn() // Set refresh token expiry time.
    })
    .catch(() => {})
    .finally(() => {})

  return { data, status, error }
}
