<script setup lang="ts">
// Emit
import { ref } from 'vue'

const emit = defineEmits<{
  (event: 'exportPdf'): void
  (event: 'discardExportPdf'): void
}>()
const loadingExportPDF = ref<boolean>(false)
const loadingDiscardPDF = ref<boolean>(false)
</script>

<template>
  <Card class="border-2 border-gray-200 shadow-inner">
    <template #title>Instructions</template>
    <template #content>
      <ol
        class="list-decimal list-outside px-4 font-jost antialiased text-font-light pb-0 lg:pb-80"
      >
        <li>Click and drag verification image to place on desired position</li>
        <li>To resize verification image, click and drag from corners</li>
        <li>Scan your verification image before applying changes</li>
      </ol>
    </template>
    <template #footer>
      <div class="flex justify-between">
        <Button
          :disabled="loadingExportPDF"
          label="Discard"
          outlined
          severity="danger"
          size="small"
          type="button"
          @click.prevent="
            () => {
              emit('discardExportPdf')
              loadingDiscardPDF = true
            }
          "
        />
        <Button
          :loading="loadingExportPDF"
          label="Export PDF"
          outlined
          severity="success"
          size="small"
          type="button"
          @click.prevent="
            () => {
              emit('exportPdf')
              loadingExportPDF = true
            }
          "
        />
      </div>
    </template>
  </Card>
</template>

<style scoped></style>
