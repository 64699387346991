<script setup lang="ts">
import { useAuthStore } from '@/stores/authentication'

import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/vue'
import XHR from '@uppy/xhr-upload'

import RemoteSources from '@uppy/remote-sources'

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import '@uppy/progress-bar/dist/style.min.css'

import { useDeleteMedia } from '@/services/user-media/'
import { useToast } from 'primevue/usetoast'
import { useVerificationStore } from '@/stores/verification'
import { useBulkOperationStore } from '@/stores/bulkOperation'

const toast = useToast()

// Auth store
const authStore = useAuthStore()

// Props definition
const props = withDefaults(defineProps<{ feature: string }>(), {
  feature: 'verification'
})

// store
const verificationStore = useVerificationStore()
const bulkOperationStore = useBulkOperationStore()

// Uppy plugin configuration
const uppyInstance = new Uppy({
  allowMultipleUploadBatches: false,
  autoProceed: false, // Start uploading when file is selected.
  restrictions: {
    maxFileSize: 26214400,
    allowedFileTypes: ['application/pdf'],
    maxNumberOfFiles: props.feature === 'bulkOperation' ? 20 : 1
  },
  debug: true
})

// Uppy XHR
const uppyXHRHeaders = {
  authorization: `Bearer ${authStore.getAccessToken}`
}
// XHR
uppyInstance.use(XHR, {
  endpoint: `${import.meta.env.VUE_API_BASE_URL}media/upload/`,
  method: 'POST',
  fieldName: 'file',
  headers: uppyXHRHeaders,
  limit: 2,
  timeout: 90 * 1000
})

// Remote sources
// Make sure to enable Google Drive api for the companion project.
// Remove 'Url' because this is not working (bug in the package)
uppyInstance.use(RemoteSources, {
  companionUrl: import.meta.env.VUE_COMPANION_URL,
  sources: ['GoogleDrive', 'OneDrive', 'Dropbox'],
  companionCookiesRule: 'same-origin'
})

// Uppy file remove after upload action configuration
uppyInstance.on('file-removed', (file, reason) => {
  if (reason === 'removed-by-user' && !!file.response?.body?.id) {
    useDeleteMedia(<number>file.response?.body?.id).then(({ status }) => {
      if (status.value == 204) {
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Successfully deleted the uploaded file.',
          life: 7000
        })
      }
    })
  }
})

// Uppy upload failed action
uppyInstance.on('upload-error', (file, error, response) => {
  console.log('Upload Error>', error.stack)
  // Logout in case of token expired.
  if (response?.status == 401) authStore.$reset()
})

// Uppy complete action configuration.
uppyInstance.on('complete', (result) => {
  // Update data in the store before file upload completed.

  if (props.feature === 'verification') {
    verificationStore.setActiveStep(1)
    verificationStore.setMediaID(<number>result.successful[0].response?.body.id)
    verificationStore.setMediaURL(<string>result.successful[0].response?.body.file)
    verificationStore.setMediaFileName(<string>result.successful[0].response?.body.original_name)
    verificationStore.setMediaFileSize(<number>result.successful[0].response?.body.file_size)
  }
})

const doneButtonHandler = () => {
  if (props.feature === 'bulkOperation') {
    const files = uppyInstance.getFiles()
    // set the last file url as a preview URL
    const lastFileUrl = files[files.length - 1]?.response?.body.file
    bulkOperationStore.setPreviewMediaURL(<string>lastFileUrl)

    // Update upload url
    files.forEach((upload) => {
      bulkOperationStore.setMediaIDList(<number>upload?.response?.body?.id)
      bulkOperationStore.setMediaFileName(<string>upload?.response?.body?.original_name)
    })

    // set active state to 1
    bulkOperationStore.setActiveStep(1)
  }
}
</script>

<template>
  <div class="lg:flex lg:flex-col lg:items-center">
    <Dashboard
      :uppy="uppyInstance"
      :props="{
        inline: true,
        width: 650,
        proudlyDisplayPoweredByUppy: false,
        showRemoveButtonAfterComplete: true,
        showProgressDetails: false,
        fileManagerSelectionType: 'files',
        doneButtonHandler: doneButtonHandler
      }"
    />
    <span class="text-font-light text-sm py-2"> Only PDF files allowed. Size limit: 25 MB </span>
    <span class="text-font-light text-sm py-2">
      <a
        class="underline"
        href="https://support.qrmark.com/hc/en-us/articles/36675667747353-Add-Verification-via-Document-Upload"
        target="_blank"
      >
        Click here</a
      >
      to learn about verifying documents by uploading files.
    </span>
  </div>
</template>

<style scoped lang="css"></style>
