import useAxios from '@/utils/useAxios'

export const useDeleteSharedUser = async (sharedUserId: number) => {
  const { data, error, status, fetchData } = useAxios<any>()

  // Update Shared User access level
  await fetchData({
    url: `/multi-user/${sharedUserId}/`,
    method: 'DELETE'
  })
  return { data, error, status }
}
