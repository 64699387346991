import useAxios from '@/utils/useAxios'

export const useRemoveBORequest = async (operation_id: string): Promise<void> => {
  const { status, fetchData } = useAxios<any>()

  await fetchData({
    url: `/bo/remove/${operation_id}/`,
    method: 'DELETE'
  })
  return { status }
}
