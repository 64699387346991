import useAxios from '@/utils/useAxios'
import { useAuthStore } from '@/stores/authentication'
import router from '@/router'
import { useVerificationStore } from '@/stores/verification'

export const useRevokeToken = async () => {
  // Auth store
  const authStore = useAuthStore()

  // Verification store
  const verificationStore = useVerificationStore()

  // Access token endpoint.
  const { error, status, fetchData } = useAxios()

  const form: FormData = new FormData()
  form.append('client_id', import.meta.env.VUE_API_CLIENT_ID)
  form.append('client_secret', import.meta.env.VUE_API_CLIENT_SECRET)
  form.append('token', `${authStore.getAccessToken}`)

  // Fetch Access token
  await fetchData({
    url: 'auth/token/revoke/',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: form
  })
    .then(() => {
      authStore.$reset()
      verificationStore.$reset()
    })
    .catch(() => {
      console.log('Revoke token component error>>', error)
    })
    .finally(() => {})
  return { status, error }
}
