import useAxios from '@/utils/useAxios'

export const useCustomDomainVerification = async (customDomainId: number) => {
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: `/custom-domain/verify/${customDomainId}/`,
    method: 'GET'
  })

  return { data, error, status }
}
