<script lang="ts" setup>
import BulkOperationSteps from '@/components/Menu/BulkOperationSteps.vue'
import VerificationFileUpload from '@/components/Form/VerificationFileUpload.vue'
import PDFEditor from '@/components/PDFUtils/PDFEditor.vue'
import { useBulkOperationStore } from '@/stores/bulkOperation'

const bulkOperationStore = useBulkOperationStore()
</script>

<template>
  <main class="mx-2 lg:mx-10">
    <h1 class="text-font-main text-xl font-semibold mt-5">
      <router-link :to="{ name: 'bulk-operation' }">Bulk Operation</router-link>
      / Generate Bulk Verification
    </h1>
    <Card>
      <template #title>
        <BulkOperationSteps />
      </template>
      <template #content>
        <!--    Upload File Component-->
        <div v-if="bulkOperationStore.activeStep == 0">
          <div class="flex flex-col xl:flex-row">
            <div class="border-gray-200 xl:border-r-2 p-5 flex-1">
              <h2 class="font-bold font-jost text-font-main py-5">Instructions</h2>
              <ul class="list-inside list-disc font-jost antialiased text-font-light space-y-6">
                <li>
                  Using this feature, you can add verification QR Codes on {20} documents at a time
                </li>
                <li>
                  All documents should be in PDF format. Maximum size limit for each file is 25 MB.
                </li>
                <li>
                  QR Code placed in one document will be replicated across all uploaded
                  documents.Hence,
                  <span class="font-semibold">
                    ensure the page(s) where you place the QR Code have identical layouts and
                    formats.
                  </span>
                </li>
                <li>
                  Select and upload multiple PDF files. Please wait till the upload completed.
                </li>
                <li>The verification name is going to be as per the file name.</li>
              </ul>
            </div>
            <div class="p-5 flex-1">
              <!--              TODO: Add file name should be unique validation before starting file upload.-->
              <VerificationFileUpload feature="bulkOperation" />
            </div>
          </div>
        </div>

        <!-- Review File Component -->
        <div v-if="bulkOperationStore.activeStep != 0">
          <PDFEditor feature="bulkOperation" />
        </div>
      </template>
    </Card>
  </main>
</template>

<style scoped></style>
