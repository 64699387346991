import useAxios from '@/utils/useAxios'

export const useSupportCreateTicket = async (email: string, subject: string, query: string) => {
  const { data, error, status, fetchData } = useAxios<{ message: string }>()

  await fetchData({
    url: 'utils/support/',
    method: 'POST',
    data: {
      email: email,
      query: query,
      subject: subject
    }
  })

  return { data, status, error }
}
