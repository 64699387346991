import useAxios from '@/utils/useAxios'
import { useAuthStore } from '@/stores/authentication'
import type { AccessTokenInterface } from '@/services/authentication/index'

export const useAuthToken = async (email: string, password: string) => {
  // Auth store
  const authStore = useAuthStore()

  // Access token endpoint.
  const { data, error, status, fetchData } = useAxios<AccessTokenInterface>()

  const form: FormData = new FormData()
  form.append('grant_type', 'password')
  form.append('client_id', import.meta.env.VUE_API_CLIENT_ID)
  form.append('client_secret', import.meta.env.VUE_API_CLIENT_SECRET)
  form.append('email', email)
  form.append('password', password)

  // Clear the local storage on reset
  localStorage.clear()

  // Fetch Access token
  await fetchData({
    url: 'auth/token/',
    method: 'POST',
    headers: { 'Content-Type': 'multipart/form-data' },
    data: form
  })
    .then(() => {
      data.value?.access_token && authStore.setAccessToken(data.value?.access_token)
      data.value?.refresh_token && authStore.setRefreshToken(data.value?.refresh_token)
      data.value?.access_token && authStore.setExpiresIn() // Set refresh token expiry time.
      authStore.setUserEmail(email) // Set auth user email
    })
    .catch(() => {})
    .finally(() => {})
  return { data, status, error }
}
