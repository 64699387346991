<script setup lang="ts">
import VerificationFileUpload from '@/components/Form/VerificationFileUpload.vue'
import PDFEditor from '@/components/PDFUtils/PDFEditor.vue'
import { useVerificationStore } from '@/stores/verification'
import VerificationSteps from '@/components/Menu/VerificationSteps.vue'

const verificationStore = useVerificationStore()
</script>

<template>
  <main class="mx-2 lg:mx-10">
    <h1 class="text-font-main text-xl font-semibold my-5">
      <router-link to="/">Verification</router-link>
      / New Verification
    </h1>
    <Card>
      <template #title>
        <VerificationSteps />
      </template>
      <template #content>
        <!--    Upload File Component-->
        <div v-if="verificationStore.activeStep == 0">
          <VerificationFileUpload feature="verification" />
        </div>

        <!-- Preview File Component -->
        <div v-if="verificationStore.activeStep != 0">
          <PDFEditor feature="verification" />
        </div>
      </template>
    </Card>
  </main>
</template>

<style scoped></style>
