<script setup lang="ts">
import Accordion, { type AccordionPassThroughOptions } from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import { DocumentDuplicateIcon } from '@heroicons/vue/24/outline'
import * as yup from 'yup'
import { ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import { useAddCustomDomain, useRemoveCustomDomain } from '@/services/customDomain'
import { useForm } from 'vee-validate'

// Form Validation
const { defineField, handleSubmit, resetForm, errors, setErrors, meta } = useForm({
  validationSchema: yup.object({
    domain: yup
      .string()
      .trim()
      .matches(
        new RegExp(
          '^(http:\\/\\/|https:\\/\\/)?([a-z0-9]+([\\-\\.]{0,1}[a-z0-9_\\-]+)\\.)+[a-z]{2,5}(:{1,5})?([a-z0-9]+([\\-\\.]{0,1}[a-z0-9_\\-]+))+\\.[a-z]{2,5}(:{1,5})?$'
        ),
        { message: 'Invalid custom domain. Use a format like docs.website.com and try again' }
      )
      .required()
  })
})

// Form field values
const txtRecord = ref('')
const currentDomainId = ref<number | null>(null)
const [domain, domainAttrs] = defineField('domain')

// Accordion state
const disableSecondAccordion = ref(true)
const activeAccordionIndex = ref<number[] | null>([0])

const { copy, copied, isSupported } = useClipboard({ source: txtRecord })

const onSubmit = handleSubmit((values) => {
  useAddCustomDomain(values.domain).then(({ data, error, status }) => {
    if (status.value == 201) {
      disableSecondAccordion.value = false
      activeAccordionIndex.value = [0, 1] // Active both accordions
      txtRecord.value = data.value?.txt
      currentDomainId.value = data.value.id
    }
    if (status.value == 400) {
      setErrors({ domain: error.value?.details.domain?.[0] })
    }
  })
})

const onCancel = () => {
  if (currentDomainId.value) {
    useRemoveCustomDomain(currentDomainId.value).then(({ status }) => {
      if (status.value == 204) {
        disableSecondAccordion.value = true
        activeAccordionIndex.value = [0]
        txtRecord.value = ''
      }
    })
  }
  resetForm({ values: { domain: '' } })
}

const emit = defineEmits(['completedDomainSetup'])
const onCompleteClick = () => {
  txtRecord.value = ''
  resetForm({ values: { domain: '' } })
  activeAccordionIndex.value = null
  disableSecondAccordion.value = true
  emit('completedDomainSetup')
}

// Primevue Accordion Pass Through
const accordionPT: AccordionPassThroughOptions = {
  accordiontab: {
    root: {
      class: 'mb-1'
    },
    header: ({ props: e }: any) => ({
      class: [
        {
          'select-none pointer-events-none cursor-default opacity-60':
            e == null ? void 0 : e.disabled
        }
      ]
    }),
    headerAction: ({ context: e }: any) => ({
      class: [
        'font-bold',
        'leading-none',
        'flex items-center',
        'relative',
        'p-3 lg:p-4',
        'rounded-t-md',
        { 'rounded-br-md rounded-bl-md': !e.active, 'rounded-br-0 rounded-bl-0': e.active },
        'border border-surface-200 dark:border-surface-700',
        'bg-surface-50 dark:bg-surface-800',
        'text-surface-600 dark:text-surface-0/80',
        { 'text-surface-900': e.active },
        'transition duration-200 ease-in-out',
        'transition-shadow duration-200',
        'hover:bg-surface-100 dark:hover:bg-surface-700/40',
        'hover:text-surface-900',
        'focus:outline-none focus:outline-offset-0 focus-visible:ring focus-visible:ring-primary-400/50 ring-inset dark:focus-visible:ring-primary-300/50',
        'cursor-pointer no-underline select-none'
      ]
    }),
    headerIcon: {
      class: 'inline-block mr-2'
    },
    headerTitle: {
      class: 'leading-none text-font-main text-sm lg:text-base'
    },
    content: {
      class: [
        'p-5',
        'rounded-tl-none rounded-tr-none rounded-br-lg rounded-bl-lg',
        'border-t-0',
        'bg-surface-0 dark:bg-surface-800',
        'border border-surface-200 dark:border-surface-700',
        'text-surface-700 dark:text-surface-0/80'
      ]
    },
    transition: {
      enterFromClass: 'max-h-0',
      enterActiveClass:
        'overflow-hidden transition-[max-height] duration-1000 ease-[cubic-bezier(0.42,0,0.58,1)]',
      enterToClass: 'max-h-[1000px]',
      leaveFromClass: 'max-h-[1000px]',
      leaveActiveClass:
        'overflow-hidden transition-[max-height] duration-[450ms] ease-[cubic-bezier(0,1,0,1)]',
      leaveToClass: 'max-h-0'
    }
  }
}
</script>

<template>
  <Accordion :activeIndex="activeAccordionIndex" :pt="accordionPT" lazy multiple>
    <AccordionTab header="Step 1: Add Custom Domain">
      <form @submit="onSubmit">
        <div class="flex flex-col lg:flex-row items-end">
          <div>
            <p class="text-font-light">
              Enter the sub-domain name that you want to create. Please enter the full domain name
              along with the sub-domain, e.g. 'docs.website.com'
            </p>
            <InputText
              type="text"
              id="customDomain"
              name="customDomain"
              class="w-full"
              size="small"
              v-model="domain"
              v-bind="domainAttrs"
              placeholder="docs.website.com"
              :disabled="!disableSecondAccordion"
            />
          </div>
          <div class="flex justify-between mt-3 lg:mt-0">
            <Button
              :disabled="!meta.valid || !disableSecondAccordion"
              class="bg-primary-main px-8 ml-2"
              label="Add"
              outlined
              severity="success"
              size="small"
              type="submit"
            />
            <Button
              class="bg-primary-main px-8 ml-2"
              label="Cancel"
              outlined
              severity="info"
              size="small"
              type="reset"
              @click="onCancel"
            />
          </div>
        </div>
        <InlineMessage v-show="errors.domain" severity="error">
          {{ errors.domain }}
        </InlineMessage>
      </form>
    </AccordionTab>
    <AccordionTab header="Step 2: Verify Domain Ownership" :disabled="disableSecondAccordion">
      <ol class="list-decimal text-font-light list-inside space-y-2 lg:space-y-4">
        <li>Go to DNS settings on your service provider's website</li>
        <li>
          Add <span class="font-semibold">CNAME</span> Record
          <ol class="list-[lower-alpha] list-inside ml-2 lg:ml-5">
            <li>
              In the Name field, enter just the sub-domain part of your full domain i.e.
              <span class="bg-gray-100 p-1 rounded">{{ domain }}</span>
            </li>
            <li>
              Point your sub-domain to
              <span class="bg-gray-100 p-1 rounded">qrmark.org</span> (Enter this value in the
              Target or Value field.)
            </li>
          </ol>
        </li>
        <li>
          Add a new <span class="font-semibold">TXT</span> record
          <ol class="list-[lower-alpha] list-inside ml-2 lg:ml-5">
            <li>Enter <span>@</span> in the hostname field (to indicate root domain)</li>
            <li>Copy the below string and enter it in the TXT value field</li>
          </ol>
        </li>
      </ol>
      <div class="float-right mb-2">
        <button
          v-if="isSupported"
          @click="copy(txtRecord)"
          class="flex border-gray-200 border-2 rounded-md p-1 space-x-2"
        >
          <!-- by default, `copied` will be reset in 1.5s -->
          <DocumentDuplicateIcon class="h-5 w-5 text-font-light" />
          <span v-if="!copied" class="text-font-light">Copy</span>
          <span v-else class="text-font-light">Copied!</span>
        </button>
        <p v-else>Your browser does not support Clipboard API</p>
      </div>
      <div>
        <textarea
          v-model="txtRecord"
          type="text"
          id="message"
          rows="2"
          disabled
          class="block p-2.5 w-full text-sm text-font-dark bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 cursor-text"
        ></textarea>
      </div>
      <p class="text-font-light py-4">
        Click on Done once both the CNAME and TXT records have been added successfully. Please note
        that sometimes it will take up to 24 hours before our system can recognise the changes on
        your subdomain.
      </p>
      <Button
        class="w-full lg:w-1/6 mt-2"
        severity="success"
        label="Complete"
        size="small"
        @click.prevent="onCompleteClick"
      />
    </AccordionTab>
  </Accordion>
</template>

<style lang="postcss" scoped></style>
