import useAxios from '@/utils/useAxios'

export const useBulkDeleteMedia = async (media_ids: number[]) => {
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: '/media/bulk/delete/',
    method: 'POST',
    data: {
      media_ids: media_ids
    }
  })

  return { status }
}
