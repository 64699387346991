<script setup lang="ts">
import { type CardPassThroughOptions } from 'primevue/card'

// Card Primevue Pass through
// The code block is defining an object named `authCardPassThrough` with properties that define the
// styling options for different parts of a `Card` component from the PrimeVue library.
const authCardPassThrough: CardPassThroughOptions = {
  root: {
    class: [
      'bg-white shadow-md rounded-md border-b-4 border-secondary-main', // Background, text colour, box shadow, and border radius.
      'w-11/12 md:w-10/12 lg:w-4/12' // Responsiveness width
    ]
  },
  body: [
    'p-5' // Padding.
  ],
  title: ['text-xl text-font-main font-medium subpixel-antialiased'], // Font size, font weight, and margin bottom.
  subtitle: {
    class: [
      'font-normal mb-2 text-gray-600' // Font weight, margin bottom, and text colour.
    ]
  },
  content: ['py-5'], // Vertical padding.
  footer: ['pt-5'] // Top padding.
}
</script>

<template>
  <Card v-bind="{ ...$attrs }" :pt="authCardPassThrough" unstyled>
    <!--    Extend PrimeVue Header Slot-->
    <template v-slot:header="">
      <slot name="header" v-bind:header="{ ...$slots.header }"></slot>
    </template>

    <!--    Extend PrimeVue Title Slot-->
    <template v-slot:title="">
      <slot name="title" v-bind:title="{ ...$slots.title }"></slot>
    </template>
    <!--    Extend PrimeVue Content Slot-->
    <template v-slot:content="">
      <slot name="content" v-bind:content="{ ...$slots.content }"></slot>
    </template>
  </Card>
</template>

<style scoped></style>
