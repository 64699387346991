export const useHeadTag = () => {
  const addTag = (
    tag: 'meta' | 'link',
    attribute: 'rel' | 'name' | 'property',
    attributeValue:
      | 'keywords'
      | 'robots'
      | 'canonical'
      | 'og:url'
      | 'og:locale'
      | 'og:site_name'
      | 'og:title'
      | 'og:image'
      | 'og:description'
      | 'og:type',
    key: 'content' | 'href',
    value: string
  ) => {
    const element = document.createElement(tag)
    element.setAttribute(attribute, attributeValue)
    element.setAttribute(key, value)
    document.head.appendChild(element)
  }

  const updateTag = (tag: 'meta', attribute: 'description' | 'keywords', value: string) => {
    const selector = document.querySelector(`${tag}[name="${attribute}"]`)
    if (selector) {
      selector.setAttribute('content', value)
    }
  }

  const removeTag = (tag: 'meta' | 'link', attribute: 'name' | 'robots' | 'rel', value: string) => {
    const metaTags = document.head.querySelectorAll(`${tag}[${attribute}="${value}"]`)
    metaTags.forEach((tag) => tag.remove())
  }
  return { addTag, updateTag, removeTag }
}
