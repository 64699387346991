const moveFocusToPrevious = (event: KeyboardEvent, currentIndex: number): void => {
  const prevIndex: number = currentIndex - 1

  if (prevIndex >= 0) {
    const prevInputElement: HTMLInputElement = document.querySelector(
      `#otpInput-${prevIndex}`
    ) as HTMLInputElement
    prevInputElement.focus()
  }
}

const moveFocusToNext = (event: KeyboardEvent, currentIndex: number, store: any): void => {
  const nextIndex: number = currentIndex + 1
  if (nextIndex < store.length) {
    const nextInputElement: HTMLInputElement = document.querySelector(
      `#otpInput-${nextIndex}`
    ) as HTMLInputElement
    nextInputElement.focus()
  }
}

const focusOnLastInputBlock = (lastIndex: number): void => {
  const lastInputElement: HTMLInputElement = document.querySelector(
    `#otpInput-${lastIndex}`
  ) as HTMLInputElement
  moveFocusToEnd(lastInputElement)
}

const moveFocusToEnd = (inputElement: HTMLInputElement): void => {
  inputElement.focus()
  //inputElement.setSelectionRange(inputElement.value.length, inputElement.value.length)
}

export const onOtpPaste = (event: ClipboardEvent, store: any): void => {
  event.preventDefault() // Prevent default paste behavior
  const pastedValue: string = event.clipboardData?.getData('text/plain') || ''

  // Validate and fill the OTP digits
  if (/^\d{6}$/.test(pastedValue)) {
    for (let i: number = 0; i < store.value.length; i++) {
      store.value[i] = pastedValue[i]
    }
    // Focus on the last input block if the last digit is filled
    if (pastedValue.length === 6) {
      const lastInputIndex: number = store.value.length - 1
      focusOnLastInputBlock(lastInputIndex)
    }
  }
}

export const onOTPInput = (event: any, index: number, store: any, otpError: any): void => {
  if (otpError.error_msg) otpError.error_msg = ''

  const inputValue = event.target.value

  store[index] = event.target.value

  if (Number.isInteger(parseInt(inputValue))) {
    if (inputValue.length === 1) {
      moveFocusToNext(event, index, store)
    } else if (inputValue.length === 0) {
      moveFocusToPrevious(event, index)
    }
  }
}

export const onOTPInputKeyDown = (event: any, index: number, store: any, otpError: any): void => {
  if (otpError.error_msg) otpError.error_msg = ''
  if (event.key === 'Backspace') {
    store[index] = ''
    moveFocusToPrevious(event, index)
  }
}
