import useAxios from '@/utils/useAxios'

export const useEmailVerification = async (email: string, otp: number, source: string) => {
  // Email verification endpoint
  const { data, error, status, fetchData } = useAxios<any>()
  // Fetch Access token
  await fetchData({
    url: 'auth/email-verification/',
    method: 'PATCH',
    params: {
      source: source ?? 'signup'
    },
    data: {
      email: email,
      otp: otp
    }
  })
  return { data, status, error }
}
