<script setup lang="ts">
// The component design reference: https://youtu.be/MszSqhEw__8
import {
  ChevronDoubleLeftIcon,
  ClipboardDocumentCheckIcon,
  CodeBracketSquareIcon,
  Cog6ToothIcon,
  DocumentCheckIcon,
  IdentificationIcon,
  InformationCircleIcon
} from '@heroicons/vue/24/outline'
import { onMounted, ref } from 'vue'

const openNavBar = ref(true)

const primaryMenu = [
  { title: 'Verifications', icon: DocumentCheckIcon, name: 'dashboard' },
  {
    title: 'Integrations',
    icon: CodeBracketSquareIcon,
    name: 'integration'
  },
  { title: 'Bulk Operation', icon: ClipboardDocumentCheckIcon, name: 'bulk-operation' },
  { title: 'Settings', icon: Cog6ToothIcon, name: 'settings' }
]

const secondaryMenu = [
  { title: 'Account', icon: IdentificationIcon, name: 'account' },
  {
    title: 'Support',
    icon: InformationCircleIcon,
    name: 'support'
  }
]
const handleMobileScreenSize = () => {
  if (window.innerWidth < 1024) {
    openNavBar.value = false
  }
}

onMounted(() => {
  window.addEventListener('resize', handleMobileScreenSize)
  handleMobileScreenSize() // Call initially to set the initial value
})
</script>
<template>
  <nav>
    <div
      class="h-screen p-2 relative bg-white shadow-md"
      :class="openNavBar ? 'w-30 lg:w-60' : 'w-20 duration-300'"
    >
      <!--    Side Nav Bar Arrow-->
      <ChevronDoubleLeftIcon
        class="bg-white w-6 p-1 text-font-light text-3xl rounded-full absolute -right-3 top-11 border cursor-pointer duration-700"
        :class="!openNavBar && 'rotate-180'"
        @click="openNavBar = !openNavBar"
      />

      <!--    Navbar Logo-->
      <div class="flex flex-col justify-between h-full">
        <!--      Primary Menu-->
        <div>
          <router-link to="/">
            <div class="flex items-center px-1">
              <img alt="QR Mark" class="w-14 cursor-pointer" src="@/assets/img/qrmark-logo.svg" />
              <h1
                class="text-primary-main text-xl lg:text-3xl font-medium cursor-pointer whitespace-nowrap"
                v-show="openNavBar"
              >
                QR MARK
              </h1>
            </div>
          </router-link>
          <hr />
          <ul class="space-y-2 mt-4">
            <template v-for="item in primaryMenu">
              <li>
                <router-link
                  :to="{ name: item.name }"
                  class="text-sm flex text-font-main gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md mb-2"
                  exact
                >
                  <component
                    :is="item.icon"
                    class="w-8 pl-2"
                    :class="{ 'text-secondary-light': $route.name === item.name }"
                  />
                  <span
                    class="text-base font-medium"
                    :class="[
                      !openNavBar && 'hidden',
                      { 'text-secondary-light': $route.name === item.name }
                    ]"
                  >
                    {{ item.title }}
                  </span>
                </router-link>
              </li>
            </template>
          </ul>
        </div>
        <!--      Secondary Menu-->
        <div class="mb-28">
          <hr />
          <ul class="mb-20 mt-4">
            <template v-for="item in secondaryMenu">
              <li>
                <router-link
                  :to="item.name"
                  class="text-sm flex text-font-main gap-x-4 cursor-pointer p-2 hover:bg-gray-100 rounded-md mb-2"
                >
                  <component
                    :is="item.icon"
                    class="w-8 pl-1"
                    :class="{ 'text-secondary-light': $route.name === item.name }"
                  />
                  <span
                    class="text-base font-medium"
                    :class="[
                      !openNavBar && 'hidden',
                      { 'text-secondary-light': $route.name === item.name }
                    ]"
                  >
                    {{ item.title }}
                  </span>
                </router-link>
              </li>
            </template>
          </ul>
          <hr />
          <ul class="flex justify-center mt-4">
            <li>
              <a
                href="https://trycon.io/legal/privacy-notice"
                target="_blank"
                v-if="openNavBar"
                class="text-font-light text-xs lg:text-sm"
              >
                Terms of Use | Privacy Policy
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped></style>
