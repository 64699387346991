import { useBulkOperationStore } from '@/stores/bulkOperation'
import useAxios from '@/utils/useAxios'

export const useCreateBORequest = async (boName: string, domain: string, pdf_info: object) => {
  const bulkOperationStore = useBulkOperationStore()

  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: '/bo/',
    method: 'POST',
    data: {
      name: boName,
      files: bulkOperationStore.getMediaIdList,
      custom_domain: domain,
      type: 1, // Bulk Generate
      pdf_info: pdf_info
    }
  })

  return { data, error, status }
}
