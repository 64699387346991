<script lang="ts" setup>
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import { useSupportCreateTicket } from '@/services/support/useSupportCreateTicket'
import { useToast } from 'primevue/usetoast'
import { useAuthStore } from '@/stores/authentication'

const toast = useToast()

const { defineField, handleSubmit, errors, meta, resetForm } = useForm({
  validationSchema: {
    subject: yup.string().min(2).required().max(200).label('subject'),
    query: yup.string().min(4).max(255).required().label('query')
  }
})

const [subject, subjectAttr] = defineField('subject')
const [query, queryAttr] = defineField('query')

const authStore = useAuthStore()

const onHandleSubmit = handleSubmit((values) => {
  useSupportCreateTicket(<string>authStore.getUserEmail, values.subject, values.query).then(
    ({ data, status, error }) => {
      if (status.value == 201) {
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: data.value?.message,
          life: 10000
        })
        resetForm()
      }
      if (status.value == 400)
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: error.value?.message,
          life: 7000
        })
    }
  )
})
</script>

<template>
  <form @submit.prevent="onHandleSubmit">
    <p class="text-font-main text-medium lg:text-lg font-medium">
      Use this option to leave us a detailed message of your query or issue. An executive will
      respond soon on your registered email address
    </p>
    <div class="w-full lg:w-1/2 pt-8">
      <div class="mb-4">
        <InputText
          id="subject"
          v-model="subject"
          placeholder="Subject"
          size="small"
          type="text"
          v-bind="subjectAttr"
        />
        <InlineMessage v-show="errors.subject" severity="error">
          {{ errors.subject }}
        </InlineMessage>
      </div>
      <div class="mb-8">
        <Textarea
          v-model="query"
          class="w-full"
          placeholder="Please explain your query in detail"
          rows="10"
          v-bind="queryAttr"
        />
        <InlineMessage v-show="errors.query" severity="error">
          {{ errors.query }}
        </InlineMessage>
      </div>
      <Button
        :disabled="!meta.valid"
        label="Submit"
        outlined
        severity="success"
        size="small"
        type="submit"
      />
    </div>
  </form>
</template>

<style scoped></style>
