import useAxios from '@/utils/useAxios'

export const useDeleteAccount = async (reason: string, feedback: string) => {
  const { data, error, status, fetchData } = useAxios<{ message: string }>()

  await fetchData({
    url: '/utils/account/delete/feedback/',
    method: 'POST',
    data: {
      reason: reason,
      feedback: feedback
    }
  })

  return { data, status }
}
