import useAxios from '@/utils/useAxios'

interface ForgetPasswordInterface {
  message: string
  readonly details?: { password: string }
}

export const useForgetPassword = async (email: string, password: string) => {
  const { data, error, status, fetchData } = useAxios<ForgetPasswordInterface>()

  await fetchData({
    url: 'auth/forget-password/',
    method: 'PATCH',
    data: {
      email: email,
      password: password
    }
  })

  return { data, error, status }
}
