import useAxios from '@/utils/useAxios'

interface ProfileUpdateInterface {
  industry: string | null
  company_name: string | null
  phone_number: string | null
}

export const useUpdateProfile = async (
  industry: string,
  companyName: string,
  stdCode: string | null,
  phoneNumber: string | null
) => {
  const { status, fetchData } = useAxios<ProfileUpdateInterface>()

  // Fetch profile
  await fetchData({
    url: 'auth/profile/update/',
    method: 'PATCH',
    data: {
      industry: industry,
      company_name: companyName,
      phone_number: stdCode && phoneNumber ? `${stdCode} ${phoneNumber}` : ''
    }
  })
  return { status }
}
