export enum PermissionsCode {
  // Verification
  CanViewVerification = 'CAN_VIEW_VERIFICATION',
  CanCreateVerification = 'CAN_CREATE_VERIFICATION',
  CanRemoveVerification = 'CAN_REMOVE_VERIFICATION',

  // Custom Domain
  CanAddCustomDomain = 'CAN_ADD_CUSTOM_DOMAIN',
  CanViewCustomDomain = 'CAN_VIEW_CUSTOM_DOMAIN',
  CanRemoveCustomDomain = 'CAN_REMOVE_CUSTOM_DOMAIN',

  // Shared User
  CanAddSharedUser = 'CAN_ADD_SHARED_USER',
  CanViewSharedUser = 'CAN_VIEW_SHARED_USER',
  CanRemoveSharedUSer = 'CAN_REMOVE_SHARED_USER',

  // Bulk Operation
  CanViewBulkOperation = 'CAN_VIEW_BULK_OPERATION',
  CanCreateBulkOperation = 'CAN_CREATE_BULK_OPERATION',
  CanRemoveBulkOperation = 'CAN_REMOVE_BULK_OPERATION'
}
