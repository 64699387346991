import useAxios from '@/utils/useAxios'

export const useResendOTP = async (email: string) => {
  const { data, error, status, fetchData } = useAxios<{ message: string }>()

  await fetchData({
    url: 'auth/resend-otp/',
    method: 'PATCH',
    data: {
      email: email
    }
  })

  return { data, status }
}
