import useAxios from '@/utils/useAxios'

export const useCustomDomainList = async (pageNumber: number) => {
  const { data, error, status, fetchData } = useAxios<any>()

  await fetchData({
    url: '/custom-domain/',
    method: 'GET',
    params: {
      page: pageNumber
    }
  })

  return { data, error, status }
}
