<script setup lang="ts">
import * as yup from 'yup'
import { useForm } from 'vee-validate'
import { onMounted, reactive } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'
import { TrashIcon } from '@heroicons/vue/24/outline'
import { useVerificationStore } from '@/stores/verification'
import { useVerifiedCustomDomainList } from '@/services/customDomain/useVerifiedCustomDomainList'
import SelectButton from 'primevue/selectbutton'
import { useBulkDeleteMedia, useDeleteMedia } from '@/services/user-media'
import { useBulkOperationStore } from '@/stores/bulkOperation'

// Props definition
const props = withDefaults(defineProps<{ feature: string; totalPage: number }>(), {
  feature: 'verification'
})

const toast = useToast()
const confirm = useConfirm()

// Store
const verificationStore = useVerificationStore()
const bulkOperationStore = useBulkOperationStore()

// Card context
const cardContext = reactive({
  fileId: verificationStore.getMediaId,
  fileName: verificationStore.getMediaFileName,
  fileSize: verificationStore.getMediaFileSize,
  isLoading: false,
  verifiedCustomDomains: [],
  selectAllPageOptions: [
    {
      name: 'Current Page',
      value: false
    },
    {
      name: 'All Pages',
      value: true
    }
  ],
  disableCustomDomainDropdown: false
})

// Form Validation
const { defineField, handleSubmit, errors, meta } = useForm({
  validationSchema: {
    domain: yup.string().required('Please select custom domain').label('domain'),
    documentName: yup
      .string()
      .max(200)
      .when('documentName', {
        is: props.feature === 'verification',
        then: yup.string().required('Document name is required').min(3)
      })
      .label('documentName'),
    selectAllPage: yup.boolean().required('Please select page for verification image'),
    boName: yup
      .string()
      .max(200)
      .when('boName', {
        is: props.feature === 'bulkOperation',
        then: yup.string().required('Bulk operation name is required.').min(3)
      })
  },
  initialValues: {
    domain: '',
    documentName: cardContext.fileName?.substring(0, cardContext.fileName.length - 4),
    selectAllPage: false,
    boName: ''
  }
})

// Field definition
const [domain, domainAttr] = defineField('domain')
const [documentName, documentNameAttr] = defineField('documentName')
const [selectAllPage, selectAllPageAttr] = defineField('selectAllPage')
const [boName, boNameAttr] = defineField('boName')

// SelectButton Primevue pass through
const selectedButtonPT = {
  root: ({ props: e }: any) => ({
    class: ['flex', { 'opacity-60 select-none pointer-events-none cursor-default': e.disabled }]
  }),
  button: ({ context: e }: any) => ({
    class: [
      'relative',
      'leading-none',
      'inline-flex items-center align-bottom basis-0 grow',
      'px-6 md:px-12 py-3 md:py-4',
      'border border-r-0',
      'first:rounded-l-md first:rounded-tr-none first:rounded-br-none',
      'last:border-r last:rounded-tl-none last:rounded-bl-none last:rounded-r-md',
      {
        'bg-surface-0 dark:bg-surface-900': !e.active,
        'text-font-light dark:text-white/80': !e.active,
        'border-surface-200 dark:border-surface-700': !e.active,
        'bg-primary-500 dark:bg-primary-400 border-primary-500 dark:border-primary-400 text-white dark:text-surface-900':
          e.active
      },
      'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-primary-400/50 dark:focus:ring-primary-300/50 focus:z-10',
      {
        'hover:bg-surface-50 dark:hover:bg-surface-800/80': !e.active,
        'hover:bg-primary-600 dark:hover:bg--300': e.active
      },
      { 'opacity-60 select-none pointer-events-none cursor-default': e.disabled },
      'transition duration-200',
      'cursor-pointer select-none overflow-hidden'
    ]
  }),
  label: {
    class: 'font-medium mx-auto'
  }
}

const fetchVerifiedCustomDomain = () => {
  useVerifiedCustomDomainList().then(({ data, error, status }) => {
    if (status.value == 200) {
      cardContext.verifiedCustomDomains = data.value.domains
    }
    if (cardContext.verifiedCustomDomains.length == 0) {
      cardContext.disableCustomDomainDropdown = true
    }
  })
}

const deleteMedia = () => {
  confirm.require({
    message: `Are you sure you want to discard ${props.feature === 'verification' ? cardContext.fileName : 'current operation'}?`,
    header: 'Confirmation',
    acceptLabel: 'Confirm',
    rejectLabel: 'Cancel',
    rejectClass: 'bg-red-500 border-red-600 hover:bg-red-600 hover:border-red-600',
    accept: () => {
      if (props.feature == 'verification') {
        useDeleteMedia(<number>cardContext.fileId).then(({ status }) => {
          if (status.value == 204) {
            toast.add({
              severity: 'success',
              summary: 'Success Message',
              detail: 'Successfully deleted the uploaded file.',
              life: 7000
            })
            verificationStore.$reset()
          }
        })
      }
      if (props.feature == 'bulkOperation') {
        useBulkDeleteMedia(bulkOperationStore.getMediaIdList).then(({ status }) => {
          if (status.value == 204) {
            toast.add({
              severity: 'success',
              summary: 'Success Message',
              detail: 'Successfully discarded the current operation.',
              life: 7000
            })
            bulkOperationStore.$reset()
          }
        })
      }
    },
    reject: () => {}
  })
}

// Emits
const emit = defineEmits<{
  (
    event: 'generateVerificationImage',
    fileId: number,
    fileName: string,
    customDomain: string,
    selectAllPage: boolean
  ): void
  (
    event: 'bulkOperationRequest',
    boName: string,
    customDomain: string,
    selectAllPage: boolean
  ): void
}>()

const onSubmit = handleSubmit((values) => {
  cardContext.isLoading = true
  if (props.feature === 'verification') {
    emit(
      'generateVerificationImage',
      <number>cardContext.fileId,
      <string>values.documentName,
      values.domain,
      values.selectAllPage
    )
  }
  if (props.feature === 'bulkOperation') {
    emit('bulkOperationRequest', <string>values.boName, values.domain, values.selectAllPage)
  }
})

onMounted(() => {
  // Fetch verified custom domain.
  fetchVerifiedCustomDomain()
})
</script>

<template>
  <Card class="border-2 border-gray-200 shadow-inner">
    <template #title>
      <span class="text-balance">{{ cardContext.fileName }}</span>
      <p class="text-sm">
        <span v-if="props.feature === 'verification'">
          Size: {{ cardContext.fileSize && (cardContext.fileSize / 1000000).toFixed(2) }} MB
        </span>
        <button class="ml-5 trash-btn float-end">
          <TrashIcon class="w-6 h-6" @click="deleteMedia" />
        </button>
      </p>
      <p class="text-sm">Page Count: {{ props.totalPage }}</p>
    </template>
    <template #content>
      <form class="border-t-2 border-gray-200" @submit="onSubmit">
        <p class="base-text text-font-main pt-4">Custom Domain</p>
        <Dropdown
          v-model="domain"
          :options="cardContext.verifiedCustomDomains"
          placeholder="Select Custom Domain"
          v-bind="domainAttr"
          :disabled="cardContext.disableCustomDomainDropdown"
        />
        <InlineMessage
          v-show="errors.domain && !cardContext.disableCustomDomainDropdown"
          severity="error"
          >{{ errors.domain }}
        </InlineMessage>
        <span v-show="cardContext.disableCustomDomainDropdown" class="text-red-500">
          Custom domain is not setup.
          <router-link :to="{ name: 'settings' }" class="underline">Click here</router-link> to
          setup.
        </span>
        <div v-if="props.feature === 'verification'">
          <p class="base-text text-font-main pt-4">Document Name</p>
          <InputText
            v-model="documentName"
            class="w-full"
            placeholder="Enter Document Name"
            size="small"
            type="text"
            v-bind="documentNameAttr"
          />
          <InlineMessage v-show="errors.documentName" severity="error">
            {{ errors.documentName }}
          </InlineMessage>
        </div>
        <div v-if="props.feature === 'bulkOperation'">
          <p class="base-text text-font-main pt-4">Bulk Operation Name</p>
          <InputText
            v-model="boName"
            class="w-full"
            placeholder="Enter Bulk Operation Name"
            size="small"
            type="text"
            v-bind="boNameAttr"
          />
          <InlineMessage v-show="errors.documentName" severity="error">
            {{ errors.documentName }}
          </InlineMessage>
        </div>

        <p class="base-text text-font-main pt-4">Add Verification Image On</p>
        <div class="pt-2">
          <SelectButton
            v-model="selectAllPage"
            :pt="selectedButtonPT"
            optionLabel="name"
            optionValue="value"
            v-bind="selectAllPageAttr"
            :options="cardContext.selectAllPageOptions"
          />
          <InlineMessage v-show="errors.selectAllPage" severity="error">
            {{ errors.selectAllPage }}
          </InlineMessage>
        </div>
        <Button
          :disabled="!meta.valid"
          class="w-full mt-5"
          :label="props.feature === 'bulkOperation' ? 'Submit' : 'Generate Verification Image'"
          severity="success"
          size="small"
          type="submit"
          :loading="cardContext.isLoading"
        />
      </form>
    </template>
    <template #footer>
      <div class="border-t-2 border-gray-200">
        <p class="font-medium text-gray-600">
          Note: Verification image will be added on the page visible in the editor and QR Code will
          work only after export.
        </p>
        <br />
        <p class="text-font-light">
          <a
            class="underline"
            href="https://support.qrmark.com/hc/en-us/articles/36731842247705-View-Document-Verification-Details"
            target="_blank"
            >Click here</a
          >
          to learn about document verifications details.
        </p>
      </div>
    </template>
  </Card>
</template>

<style scoped lang="postcss">
.base-text {
  @apply font-jost text-font-light;
}

.label {
  @apply absolute text-sm text-gray-500 duration-300 cursor-text
  transform -translate-y-3 scale-75 top-1 z-10 origin-[0] bg-gray-100 px-2
  peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500
  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
  peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-3 left-1;
}

.trash-btn {
  @apply p-1 border-2 border-gray-500 rounded-md shadow hover:border-primary-300 hover:text-primary-300;
}
</style>
