import { type Ref } from 'vue'
import useAxios, { type UseAxiosResponse } from '@/utils/useAxios'

// Custom domain list response interface

interface CustomDomainListResponse {
  data: Ref<{ domains: string[] }>
  error: Ref<any>
  status: Ref<number>
}

export const useVerifiedCustomDomainList = async () => {
  const { data, error, status, fetchData }: UseAxiosResponse<any> =
    useAxios<CustomDomainListResponse>()

  await fetchData({
    url: '/custom-domain/list/',
    method: 'GET'
  })

  return { data, error, status }
}
