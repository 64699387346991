import useAxios from '@/utils/useAxios'

export const useDiscardPDF = async (fileId: number) => {
  const { status, fetchData } = useAxios<any>()

  await fetchData({
    url: '/pdf/discard/',
    method: 'POST',
    data: {
      original: fileId
    }
  })
  return { status }
}
